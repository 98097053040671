import { Component, Input, Output, EventEmitter } from "@angular/core";
import { drawerProfileConfig } from './dn-profile-drawer.model';

@Component({
    selector: 'dn-profile-drawer',
    templateUrl: './dn-profile-drawer.component.html',
    styleUrls: ['./dn-profile-drawer.component.scss']
})

export class DnProfileDrawerComponent {

    @Input() drawerProfileConfig:drawerProfileConfig;
    @Output() closedDrawer: EventEmitter<any> = new EventEmitter();
    @Output() displayData: EventEmitter<any> = new EventEmitter();

    constructor(){

    }

    ngOnInit(){ }

    closeDrawer(){
        this.drawerProfileConfig.isActive = false;
        this.closedDrawer.emit();
    }
}
