import { Component, Input, Output, EventEmitter } from "@angular/core";
import { IFilterSelectConfig } from './dn-filter-select.model';

@Component ({
    selector: 'dn-filter-select',
    templateUrl: './dn-filter-select.component.html',
    styleUrls: ['./dn-filter-select.component.scss']
})

export class DnFilterSelectComponent {
    @Input() config:IFilterSelectConfig;
    @Input() dataModel:Object;
    @Output() onSelectionChange: EventEmitter<any> = new EventEmitter();
    @Output() onBlur: EventEmitter<any> = new EventEmitter();

    constructor(){
    }

    ngOnInit(){
        if(typeof this.config.api == 'undefined'){
            this.config.api = {};
        }
    }

    selectionChangeCall(event){
        this.onSelectionChange.emit(event);
    }

    blurCall(){
        setTimeout(() => {
            this.config.api.refreshValidation();
            this.onBlur.emit();
        }, 100)
    }
    
}