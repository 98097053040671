import { Component, Output, EventEmitter, Input } from "@angular/core";
import { ISelectConfig } from '../../dn-components/dn-form-select/src/dn-form-select.model';
import { ITextConfig } from '../../dn-components/dn-form-text/src/dn-form-text.model';
import { SearchService } from 'src/services/search.service';
import { IFilterSelectConfig } from 'src/dn-components/dn-filter-select/src/dn-filter-select.model';
import { NotificationService } from 'src/services/notification.service';
@Component({
    selector: 'notification-view',
    templateUrl: './notification-view.component.html',
    styleUrls: ['./notification-view.component.scss']
})
export class NotificationViewComponent {
    displayAllFiles: boolean = false;
    public filterSearch = {
        selectedCategory: 'Include Only',
        selectedTag: 'Include Only',
        autoSuggestText: '',
        from: '',
        to: '',

    }

    notificationTypes: any[] = ['Notifications', 'Announcement'];
    @Output() closeClicked: EventEmitter<any> = new EventEmitter();
    showFilters: boolean = false;
    public selectCategories = [
        { value: 'all-0', viewValue: 'All' },
        { value: 'Assesment-1', viewValue: 'Assesment' },
        { value: 'CVs-2', viewValue: 'CVs' },
        { value: 'Templates-3', viewValue: 'Templates' },
        { value: 'Surveys-2', viewValue: 'Surveys' }
    ];
    public selecteModel = 'all-0';
    public dataSet = [
        {
            name: 'Assesment Name-01',
            description: 'Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum',
            category: 'Assessment',
            rating: '4.5',
            file: {
                name: 'FileName-01.pdf',
                size: '1.5MB'
            }
        },
        {
            name: 'CV Name-01',
            description: 'Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum',
            category: 'CV',
            rating: '4.5',
            file: {
                name: 'FileName-02.docx',
                size: '1.5MB'
            }
        },
        {
            name: 'Survery Name-01',
            description: 'Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum',
            category: 'Surveys',
            rating: '4.5',
            file: {
                name: 'FileName-03.xlsx',
                size: '997KB'
            }
        }
    ];
    public categories = [
        {
            title: 'Survey',
            list: [
                {
                    name: 'Survey Name',
                    status: 'Meta Data',
                    rating: '4.5',
                    type: 'text'
                },
                {
                    name: 'Survey Name',
                    status: 'Meta Data',
                    rating: '5.5',
                    type: 'text'
                },
                {
                    name: 'Survey Name',
                    status: 'Meta Data',
                    rating: '3.5',
                    type: 'text'
                },
                {
                    name: 'Survey Name',
                    status: 'Meta Data',
                    rating: '4.0',
                    type: 'text'
                },
                {
                    name: 'Survey Name',
                    status: 'Meta Data',
                    rating: '3.0',
                    type: 'text'
                }
            ]
        },
        {
            title: 'CVS',
            list: [
                {
                    name: 'Survey Name',
                    status: 'Meta Data',
                    rating: '4.5',
                    type: 'link'
                },
                {
                    name: 'Survey Name',
                    status: 'Meta Data',
                    rating: '5.5',
                    type: 'link'
                },
                {
                    name: 'Survey Name',
                    status: 'Meta Data',
                    rating: '3.5',
                    type: 'link'
                },
                {
                    name: 'Survey Name',
                    status: 'Meta Data',
                    rating: '4.0',
                    type: 'link'
                },
                {
                    name: 'Survey Name',
                    status: 'Meta Data',
                    rating: '3.0',
                    type: 'link'
                }
            ]
        }
    ];
    public selectConfig: ISelectConfig = {
        fieldKey: 'selectedCategory',
        dataKey: 'viewValue',
        options: [
            { value: 'all-0', viewValue: 'All' },
            { value: 'Assesment-1', viewValue: 'Assesment' },
            { value: 'CVs-2', viewValue: 'CVs' },
            { value: 'Templates-3', viewValue: 'Templates' },
            { value: 'Surveys-2', viewValue: 'Surveys' }
        ]
    };
    public textConfig: ITextConfig = {
        fieldKey: 'searchedText',
        attributes: {
            label: 'Search',
            appearance: 'fill',
            prefixIcon: 'search'
        }
    };
    public autoSuggestConfig: ITextConfig = {
        fieldKey: 'autoSuggestText',
        attributes: {
            placeholder: 'Auto Suggest field',
            appearance: 'fill',
        }
    };
    public uploadDateConfig = {
        from: {
            fieldKey: 'from',
            attributes: {
                type: 'text',
                appearance: 'fill',
                disable: false,
                // class: 'col-xs-4',
                isMandatory: false,
                maxDate: new Date()
            }
        },
        to: {
            fieldKey: 'to',
            attributes: {
                type: 'text',
                appearance: 'fill',
                disable: false,
                // class: 'col-xs-4',
                isMandatory: false,
                maxDate: new Date()
            }
        },
    };
    public commonModel = {
        selectedCategory: "All",
        searchedText: ""
    }
    public selectCategoryConfig: IFilterSelectConfig = {
        fieldKey: 'selectedCategory',
        dataKey: 'name',
        options: [
            { name: 'Include Only' },
            { name: 'Exclude Only' },
        ],
        attributes: {
            appearance: 'fill',
            class: 'z10 col-xs-6 float-initial alignSelectBox',
            // label: ''
        }
    };
    public selectTagConfig: IFilterSelectConfig = {
        fieldKey: 'selectedTag',
        dataKey: 'name',
        options: [
            { name: 'Include Only' },
            { name: 'Exclude Only' },
        ],
        attributes: {
            appearance: 'fill',
            class: 'z10 col-xs-6 float-initial alignSelectBox',
            // label: ''
        }
    };
    dummyArray = [...this.dataSet];
    testArray = [];
    categoryOptions: any;
    tagOptions: { label: string; value: string; }[];
    notifications: any[] = [];
    announcements: any[] = [];
    detailView = false;
    annoucementDetails: any;
    unreadAnnouncements = 0;
    unreadNotifications = 0;
    today = new Date().toDateString();
    //model = ""
    constructor(
        private searchService: SearchService,
        private notificationService: NotificationService,
    ) {
        this.getAllNotification();
        this.getAlAnnnouncements();
        this.getAllCategory();
        this.getAllTags();
        this.getAllFiles();
    }
    getAllNotification() {
        this.unreadNotifications = 0;
        this.notificationService.getAllNotification().subscribe(
            notifications => {
                this.notifications = []
                this.notifications = notifications;
                this.notifications.forEach(
                    item => {
                        
                        if(item.status == 'UnRead') {
                            this.unreadNotifications += 1;
                        }
                        // if (item.description.toLowerCase().match(item.name.toLowerCase())) {
                        item.description = item.description.toString().replace(item.sender, '');
                        // }
                    }
                );

            }
        );

    }
    getAlAnnnouncements() {
        this.unreadAnnouncements = 0;
        this.notificationService.getAllAnnouncements().subscribe(
            data => {
                this.announcements = []
                this.announcements = data;
                this.announcements.forEach(element => {
                    if(element.status == 'UnRead') {
                        this.unreadAnnouncements += 1;
                    }
                })
            }
        );

    }
    IndexFn(event) {
        
        
        if(event.tab.textLabel.split(' ')[0] == 'Notifications') {
            this.getAllNotification()
            this.detailView = false
        } else if(event.tab.textLabel.split(' ')[0] == 'Announcement') {
            this.getAlAnnnouncements()
        }
    }
    viewAnnouncement(item){
        
        
        this.detailView = true;
        this.annoucementDetails = item;
    }
    getAllCategory() {
        this.categoryOptions = [
            { label: 'All Category', value: 'All Category' },
            { label: 'Category Name -01', value: 'Category Name -01' },
            { label: 'Category Name -02', value: 'Category Name -02' },
            { label: 'Category Name -03', value: 'Category Name -03' },
        ]
    }
    getAllTags() {
        this.tagOptions = [
            { label: 'All Tags', value: 'All Tags' },
            { label: 'Tags Name -01', value: 'Tags Name -01' },
            { label: 'Tags Name -02', value: 'Tags Name -02' },
            { label: 'Tags Name -03', value: 'Tags Name -03' },
        ]
    }
    getAllFiles() {
        this.searchService.getSearchResults().subscribe(
            results => {
                this.testArray = results;
            });
    }
    searchKeyUp(evt) {
        this.searchService.getSearchResults().subscribe(
            results => {
                this.testArray = results.filter(item =>
                    (item.file.toLowerCase().match(this.commonModel.searchedText.toLowerCase()))
                    ||
                    (item.score.toString().match(this.commonModel.searchedText.toLowerCase()))
                    ||
                    (item.category.toLowerCase().match(this.commonModel.searchedText.toLowerCase()))
                    ||
                    (item.description.toLowerCase().match(this.commonModel.searchedText.toLowerCase()))
                );
            }
        );
        // this.dataSet = this.dummyArray.filter(item => 
        //     (item.name.toLowerCase().match(this.commonModel.searchedText.toLowerCase()))
        //     ||
        //     (item.description.toLowerCase().match(this.commonModel.searchedText.toLowerCase())) 
        //     ||
        //     (item.category.toLowerCase().match(this.commonModel.searchedText.toLowerCase())) 
        //     ||
        //     (item.file.name.toLowerCase().match(this.commonModel.searchedText.toLowerCase())) 
        // );
    }
    toggleFilter() {
        this.showFilters = !this.showFilters;
    }
    closeClick() {
        this.closeClicked.emit();
    }
    dateString(date) : string{
        let returnDate = new Date(date);
        return returnDate.toDateString();
    }
}