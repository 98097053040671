import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class RouteGuardService {

  constructor(  private authService: AuthenticationService,
    private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

      if (this.authService.isUserLoggedIn()){
        return true;
      }else{
        this.router.navigate(['']); //redirect to login
  
        return false;
      }
    }
}
