import { NgModule } from "@angular/core";
import { DnProfileDrawerComponent } from './dn-profile-drawer.component';
import { MatIconModule } from '@angular/material';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [ CommonModule, MatIconModule ],
    declarations: [ DnProfileDrawerComponent ],
    exports: [ DnProfileDrawerComponent ]
})

export class DnProfileDrawerModule {
    constructor(){
        
    }
}