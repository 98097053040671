import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { MatIconModule, MatInputModule } from '@angular/material';
import { DnListBoxComponent } from './dn-list-box.component';
import { DnValidationModule } from '../../dn-validation/src/package.module';
import {ListboxModule} from 'primeng/listbox';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@NgModule({
    imports: [ CommonModule, MatIconModule, ListboxModule, ReactiveFormsModule, FormsModule, MatInputModule, DnValidationModule],
    declarations: [ DnListBoxComponent ],
    exports: [ DnListBoxComponent ]
})

export class DnListBoxModule { }