import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnInit,
  ViewEncapsulation,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { ISelectConfig } from "../../dn-form-select/src/dn-form-select.model";
import { Table } from "primeng/table";
import * as _ from "lodash";
import {
  PageEvent,
  MatPaginator,
  MatSelectionList,
  MatCheckbox,
  MatListOption,
} from "@angular/material";
import { FilterUtils, LazyLoadEvent } from "primeng/api";
import * as moment from "moment";
import { ITableColumn, ITableConfig } from "./dn-table.model";
import { NzPopoverDirective } from "ng-zorro-antd";
import { isNumber } from 'lodash';
import { SESSION_USER } from "src/services/authentication.service";
@Component({
  selector: "dn-table",
  templateUrl: "./dn-table.component.html",
  styleUrls: ["./dn-table.component.scss"],
})
export class DnTableComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() parentEle: DnTableComponent;
  @Input() nestedIndex: number;
  @Input() dataModel: any[];
  @Input() disableRadio = false;
  selectedChild: any = {};
  ratingVal: number;
  isLoading = false;
  searchText: string;
  defaultHeight = "calc(92vh - 240px)";
  public colors = [];
  nestedData = [];
  pageSize = 20;
  length = 0;
  pageIndex = 0;
  currentPageFirstNo = 1;
  currentPageLastNo = 15;
  pageSizeOptions: number[] = [10, 15, 20, 25, 50, 100];
  currentPageNo = 1;
  finalPageNo = 15;
  math = Math;
  selectedRows = [];
  selectedFilterOption = [];
  repeatCols = [];
  dataSource = [];
  editClone: { [s: string]: any } = {};
  rowActiveEdit = {
    index: null,
    data: null,
  };
  public selectConfig: ISelectConfig = {
    fieldKey: "selectedRows",
    dataKey: "rows",
    options: [{ rows: 5 }, { rows: 10 }, { rows: 50 }],
    attributes: {
      appearance: "outline",
    },
  };

  dateFilterPresetConfig = {
    fieldKey: "preset",
    dataKey: "preset",
    returnKey: "value",
    options: [
      { preset: "Last 7 Days", value: "7days" },
      { preset: "Specific Date", value: "date" },
      { preset: "Date Range", value: "range" },
    ],
    attributes: {
      placeholder: "Select",
      class: "dateFilterBox",
      appearance: "outline",
    },
  };

  public rowModel = {
    selectedRows: 10,
  };

  public filterColCount: number;
  @Input() config: any;
  @Output() outputTotal: EventEmitter<any> = new EventEmitter();
  @Output() onLinkClick: EventEmitter<any> = new EventEmitter();
  @Output() onSelectDropdownChange: EventEmitter<any> = new EventEmitter();
  @Output() onSelection: EventEmitter<any> = new EventEmitter();
  @Output() onRowSubmit: EventEmitter<any> = new EventEmitter();
  @Output() onRowDelete: EventEmitter<any> = new EventEmitter();
  @Output() onChangeStatus: EventEmitter<any> = new EventEmitter();
  @Output() onRatingChange: EventEmitter<any> = new EventEmitter();
  @ViewChild("dt", { static: false }) dataTable: Table;
  @ViewChild("matPaginator", { static: false }) matPaginator: MatPaginator;
  @ViewChildren(NzPopoverDirective) popOvers: QueryList<NzPopoverDirective>;
  @ViewChild("showSelectedToggle", { static: false })
  showSelectedToggle: MatCheckbox;
  constructor(private _cdr: ChangeDetectorRef) {
    // this.dataSource = _.cloneDeep(this.config.rowData)
  }
  public loadDataOnScroll(event: LazyLoadEvent) {
    let handleData = [];
    if (this.config.rowData) {
      handleData = _.cloneDeep(this.config.rowData);
      if (event.multiSortMeta) {
        event.multiSortMeta.forEach((element) => {
          handleData = _.orderBy(
            handleData,
            [element.field],
            [element.order == 1 ? "asc" : "desc"]
          );
        });
      }
      if (!_.isEmpty(event.filters)) {
        Object.keys(event.filters).forEach((key) => {
          let filterMeta = event.filters[key];
          handleData = FilterUtils.filter(
            handleData,
            [key],
            filterMeta.value.toString(),
            filterMeta.matchMode
          );
          this.dataTable.filteredValue = handleData;
          this.config.filteredData = handleData;
          this.length = handleData.length;
        });
      } else {
        this.length = handleData.length;
      }
      this.dataSource = handleData.slice(event.first, event.first + 80);
      this._cdr.detectChanges();
    }
  }

  dateFilterChange(event, options) {
    switch (event.value) {
      case "7days":
        const range = [
          moment().subtract(6, "days").startOf("day").toDate(),
          moment().endOf("day").toDate(),
        ];
        options.dateRange = range;
        break;
      case "date":
        options.date = null;
        break;
      case "range":
        options.dateRange = [];
        break;
      default:
        break;
    }
  }

  applyDateColFilter(dialog, col) {
    if (col.dateFilterOptions.preset == "date") {
      if (col.dateFilterOptions.date != null) {
        this.dataTable.filter(
          [
            moment(col.dateFilterOptions.date).startOf("day"),
            moment(col.dateFilterOptions.date).endOf("day"),
          ],
          col.field,
          "dateRange"
        );
        dialog.hide();
      }
    } else {
      if (col.dateFilterOptions.dateRange.length > 0) {
        this.dataTable.filter(
          [
            moment(col.dateFilterOptions.dateRange[0]),
            moment(col.dateFilterOptions.dateRange[1]),
          ],
          col.field,
          "dateRange"
        );
        dialog.hide();
      }
    }
  }

  resetDateColFilter(dialog, col) {
    col.dateFilterOptions.preset = null;
    col.dateFilterOptions.date = null;
    col.dateFilterOptions.dateRange = [];
    this.dataTable.filter([], col.field, "dateRange");
    dialog.hide();
  }

  getIsFilterAppiled(col) {
    let isFilterApplied = false;
    col.customFilterOptions.forEach((element) => {
      if (element.selected) isFilterApplied = true;
    });
    return isFilterApplied;
  }
  getIsDateFilterAppiled(col) {
    return (
      (col.dateFilterOptions.preset == "range" &&
        col.dateFilterOptions.dateRange.length > 0) ||
      (col.dateFilterOptions.preset == "date" &&
        col.dateFilterOptions.dateRange != null) ||
      col.dateFilterOptions.preset == "7days"
    );
  }
  saveFilterState(col, event) {
    let filterOptions = [];
    filterOptions = col.customFilterOptions;
    filterOptions.forEach((filterElement) => {
      if (filterElement.value == event.option._value) {
        filterElement.selected = event.option._selected;
      }
    });
  }

  applyColFilter(dialog, col, list: MatSelectionList) {
    //
    const filterValues = list.selectedOptions.selected.map(
      (i: MatListOption) => i.value
    );
    this.dataTable.filter(filterValues, col.field, "in");
    dialog.hide();
  }
  resetColFilter(dialog, col, list: MatSelectionList) {
    list.selectedOptions.clear();
    let filterOptions = [];
    filterOptions = col.customFilterOptions;
    filterOptions.forEach((filterElement) => {
      filterElement.selected = false;
    });
    this.dataTable.filter(list._value, col.field, "in");
    dialog.hide();
  }

  getDataAsPerShowSelected(colData: any[], isShowSelected) {
    let selectedData = [];
    let listValues: any[] = [];
    colData.forEach((filter) => {
      if (filter.selected) {
        listValues.push(filter.value);
      }
    });
    selectedData = colData.filter((e) => {
      return listValues.includes(e.value);
    });
    if (isShowSelected) {
      return selectedData;
    }
    return colData;
  }
  addToFilter(newFilterValue: string, options: any[]) {
    const newOption = {
      label: newFilterValue,
      value: newFilterValue,
      selected: true,
    };
    options.push(newOption);
    this.showSelectedToggle.checked = true;
    this._cdr.detectChanges();
    this.searchText = null;
  }
  ngOnChanges(i: SimpleChanges) {
    this.updatePaginationLength();
    if (this.dataModel && this.config) {
      this.config.rowData = this.dataModel;
      if (this.config.nestedTableConfig) {
        this.config.nestedTableConfig.rowData = this.dataModel;
        this.manageNestedData();
      }
    }
  }
  updatePaginationLength() {
    if (this.config.rowData)
      this.length = this.config.rowData.length;
  }
  ngAfterViewInit() {
    //s
  }
  manageNestedData() {
    if (this.config.isNestedTable) {
      this.repeatCols = Array(this.config.level).fill(this.config.level);
      switch (this.config.nestedLevel) {
        case 1:
          this.nestedData =
            this.parentEle.dataTable && this.parentEle.dataTable.filteredValue
              ? this.parentEle.dataTable.filteredValue[this.nestedIndex][this.config.childrenField] :
              this.config.rowData[this.nestedIndex][this.config.childrenField];
          break;
        case 2:
          this.nestedData =
            this.config.rowData[this.parentEle.nestedIndex][
            this.config.childrenField
            ][this.nestedIndex][this.config.childrenField];
          break;
        case 3:
          this.nestedData =
            this.config.rowData[this.parentEle.parentEle.nestedIndex][
            this.config.childrenField
            ][this.parentEle.nestedIndex][this.config.childrenField][
            this.nestedIndex
            ][this.config.childrenField];
          break;
        default:
          this.nestedData = this.config.rowData;
          break;
      }
      // this.config.rowData = this.nestedData
      setTimeout(() => {
        if (this.nestedData) {
          this.dataTable.value = this.nestedData;
          this.dataTable.selection = this.dataTable.value.find(
            (value) => value.statusText == "PO Received"
          );
        }
      }, 10);
    }
  }
  ngOnInit() {
    this.manageNestedData();
    FilterUtils["doesNotContains"] = (value, filter): boolean => {
      return value.toLowerCase().indexOf(filter.toLowerCase()) === -1;
      // return filter != value;
    };
    FilterUtils["dateRange"] = (value, filter): boolean => {
      let startDate = new Date(filter[0]).getTime();
      let endDate = new Date(filter[1]).getTime();
      if (value.length == 10 && typeof value == "string") {
        value = parseInt(value) * 1000;
      }
      let mValue = moment(value);
      let cellValue = mValue.toDate().getTime();
      // if(typeof(value) == 'number') {
      //     cellValue = parseInt(value) * 1000
      // } else {

      // }
      return cellValue >= startDate && cellValue <= endDate;
    };
    this.colors = [
      { label: "Red", value: "Red" },
      { label: "Gray", value: "Gray" },
      { label: "White", value: "White" },
      { label: "Blue", value: "Blue" },
      { label: "Yellow", value: "Yellow" },
    ];
    this.updatePaginationLength();
    this.filterColCount = _.filter(this.config.colDef, function (o) {
      return o.enableFilter;
    }).length;
    setTimeout(() => {
      this.dataTable.onLazyLoad.emit(this.dataTable.createLazyLoadMetadata());
    }, 100);
  }

  public displayToggle(row): boolean {
    return (row['id'] !== localStorage.getItem(SESSION_USER));
  }

  cellLinkClickCall(event, col, row, option, rowIndex) {
    event["tableData"] = {
      column: col,
      rowData: row,
      option: option,
      rowIndex: rowIndex,
    };
    this.onLinkClick.emit(event);
  }
  cellNestedLinkClick(event, rowIndex) {
    event["parentIndex"] = rowIndex;
    this.onLinkClick.emit(event);
  }
  actionLinkClickCall(event, col, row, extra, rowIndex) {
    const evt = {
      event: event,
      column: col,
      rowData: row,
      extra: extra,
      rowIndex: rowIndex,
    };
    this.onLinkClick.emit(evt);
  }

  pageEvent(event): PageEvent {
    this.pageSize = event.pageSize;
    this.dataTable.first = event.pageIndex * this.dataTable.rows;
    return;
  }

  getMappedData(data: any[], field: string): string {
    if (data.length != 0) return data.map((i) => i[field]).join(", ");
    else return "No Plan Mapped";
  }

  exportExcel() {
    let exportData = [];
    if (this.selectedRows.length == 0) {
      exportData =
        this.config.filteredData.length > 0
          ? this.config.filteredData
          : this.config.rowData;
    } else {
      exportData = this.selectedRows;
    }
    const wscols = [];
    this.config.colDef.forEach((element) => {
      wscols.push({ width: 20 });
    });
    const newArray = exportData.map((o) => {
      let returnValue = {};
      this.config.colDef.forEach((element) => {
        if (element.type != "icon" && element.type != "text-actions") {
          if (element.type == "date" || element.type == "epochDate") {
            if (o[element.field])
              o[element.field] = moment(o[element.field]).format("MM/DD/YYYY");
            else o[element.field] = "";
          } 
          if (element.type == 'nestedColumn') {
            returnValue[element.header] = o[element.field][element.nestItem];
          } else {
            returnValue[element.header] = o[element.field];
          }
        }
      });
      return returnValue;
    });
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(newArray);
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      this.saveAsExcelFile(excelBuffer, "PMR");
    });
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  }
  saveAsCSVFile(buffer: any, fileName: string): void {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".csv";
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  }
  exportCSV() {
    let exportData = [];
    if (this.selectedRows.length == 0) {
      exportData = this.config.rowData;
    } else {
      exportData = this.selectedRows;
    }
    const newArray = exportData.map((o) => {
      let returnValue = {};
      this.config.colDef.forEach((element) => {
        if (element.type != "icon" && element.type != "text-actions") {
          returnValue[element.field] = o[element.field];
        }
      });
      //   return { userName: o.userName, role: o.role, contact: o.contact,email : o.email, status: o.status};
      return returnValue;
    });
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(newArray);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      this.saveAsCSVFile(excelBuffer, "PMR");
    });
  }
  exportPdf() {
    let exportData = [];
    if (this.selectedRows.length == 0) {
      exportData = this.config.rowData;
    } else {
      exportData = this.selectedRows;
    }
    let data = [];
    let newData = [];
    data = this.config.colDef;
    data.forEach((element) => {
      if (element.type != "icon" && element.type != "text-actions") {
        element["dataKey"] = element["field"];
      }
    });
    data.forEach((element) => {
      if (element.type != "icon") {
        // Check for date entry
        if (element.type == "date") {
          element["field"] = new Date(element["field"]);
        }
        newData.push(element);
      }
    });
    data.forEach((element) => {
      if (element.type != "icon") {
        newData.push(element);
      }
    });
  }

  formatFileSize(bytes, decimalPoint?) {
    if (bytes == 0) return "0 Bytes";
    var k = 1000,
      dm = decimalPoint || 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  onRowSelect(event) {
    // event['data'] = event.data
    this.selectedRows = event;
    this.onSelection.emit(event);
  }

  resetPaginator(event) {
    this.matPaginator.firstPage();
    this.dataTable.first = 0;
    this.pageSize = event.value;
  }

  onFilter(e) {
    this.matPaginator.firstPage();
    this.config.filteredData = e.filteredValue;
    this.dataTable.first = 0;
    this.pageIndex = 0;
    this.length = this.config.filteredData.length;
    // this.isLoading = true;
    //
    // this.dataTable.onFilter.subscribe(response=> {
    //     this.isLoading = false;
    //
    // })
  }

  onSearch(evt) {
    this.matPaginator.firstPage();
    this.dataTable.filterGlobal(evt, "contains");
  }

  onSort(e) {
    this.matPaginator.firstPage();
    this.dataTable.first = 0;
    this.pageIndex = 0;
  }

  onRowEditInit(data, index) {
    this.editClone[data[this.config && this.config.rowId]] = { ...data };
    this.rowActiveEdit = {
      index: index,
      data: data,
    };
  }

  selectDropdownValueChange(event, data, columnName) {
    const changeEvent = {
      rowData: data,
      value: event.value,
      column: columnName,
    };
    this.onSelectDropdownChange.emit(changeEvent);
  }

  nestedSelectDropdownValueChange(event) {
    this.onSelectDropdownChange.emit(event);
  }

  // changeStatus(event, data, index, icon) {
  //   let status = "Active";
  //   if (event.checked) {
  //     // this.config.rowData[index][icon.toggleField] = "Active"
  //     this.config.rowData[index][icon.toggleTextField] = icon.dataOnChecked;
  //     status = icon.dataOnChecked;
  //   } else {
  //     this.config.rowData[index][icon.toggleTextField] = icon.dataOnUnchecked;
  //     // this.config.rowData[index][icon.toggleField] = "Deactive"
  //     status = icon.dataOnUnchecked;
  //   }
  //   this.onChangeStatus.emit(status);
  // }

  changeStatus(event, data, index, icon) {
    if (event.checked) {
      // this.config.rowData[index][icon.toggleField] = "Active"
      this.config.rowData[index][icon.toggleTextField] = icon.dataOnChecked
    } else {
      this.config.rowData[index][icon.toggleTextField] = icon.dataOnUnchecked
      // this.config.rowData[index][icon.toggleField] = "Deactive"
    }
    this.onChangeStatus.emit(data);
  }

  onRowEditSave(data, htmlRef: HTMLTableRowElement) {
    if (htmlRef.getElementsByClassName("ng-invalid").length == 0) {
      this.dataTable.saveRowEdit(data, htmlRef);
      delete this.editClone[data[this.config && this.config.rowId]];
      this.length = this.config.rowData.length;
      this.onRowSubmit.emit(data);
      this.rowActiveEdit = {
        index: null,
        data: null,
      };
    }
    // else {
    //     const length = htmlRef.getElementsByClassName('ng-invalid').length
    //     for (let index = 0; index < length; index++) {
    //         if(htmlRef.getElementsByClassName('ng-invalid')[index].hasAttribute('appearance')) {
    //             htmlRef.getElementsByClassName('ng-invalid')[index].classList.add('mat-form-field-invalid')
    //
    //         }
    //     }
    // }
  }

  onRowEditDelete(data, index) {
    this.onRowDelete.emit({
      data: data,
      index: index,
    });
  }

  onRowEditCancel(data, index: number) {
    let isNewData = false;
    if (data[this.config && this.config.rowId] == null) {
      isNewData = true;
    }
    if (!isNewData) {
      this.config.rowData[index] = this.editClone[data[this.config && this.config.rowId]];
      delete this.editClone[data[this.config && this.config.rowId]];
    } else {
      this.config.rowData.splice(index, 1);
      delete this.editClone["newData"];
    }
    this.rowActiveEdit = {
      index: null,
      data: null,
    };
  }

  // getColTotal(col: ITableColumn) {
  //   const dataArray: any[] = this.config.rowData;
  //   if (col.currencyCol) {
  //     const total = dataArray.reduce((val, data) => {
  //       if (typeof data[col.field] == "string")
  //         data[col.field] = parseInt(data[col.field]);
  //       return data[col.field] + val;
  //     }, 0);
  //     if (col.isFinalOutput) {
  //       this.outputTotal.emit(total);
  //     }
  //     return total;
  //   }
  // }
  getColTotal(col: ITableColumn) {
    const dataArray: any[] = this.config.rowData;
    if (col.currencyCol) {
      const total = dataArray.reduce((val, data) => {
        if (typeof data[col.field] == 'string') data[col.field] = parseInt(data[col.field]);
        if (data[col.field] == undefined || data[col.field] == null || isNaN(data[col.field]) || !isNumber(data[col.field])) data[col.field] = 0;
        if (val == undefined || val == null || isNaN(val) || !isNumber(val)) val = 0;
        return data[col.field] + val
      }, 0);
      if (col.isFinalOutput) {
        this.outputTotal.emit(total)
      }
      return total
    }
  }
  getAdditionalCost(col: ITableColumn) {
    const dataArray: any[] = this.config.rowData;
    const colArray: any[] = this.config.colDef;
    const totalColValues = [];
    let totalCost = 0;
    colArray.forEach((colData) => {
      if (colData.currencyCol) {
        totalColValues.push(
          dataArray.reduce((val, data) => {
            if (typeof data[colData.field] == "string")
              data[colData.field] = parseInt(data[colData.field]);
            return data[colData.field] + val;
          }, 0)
        );
      }
    });
    totalCost = totalColValues.reduce((val, data) => {
      return data + val;
    }, 0);
    this.outputTotal.emit(totalCost);
    return totalCost;
    // if(col.currencyCol) {
    //     const finalTotal = totalCost + dataArray.reduce((val, data)=> {
    //         if(typeof data[col.field] == 'string') data[col.field] = parseInt(data[col.field])
    //         return data[col.field] + val
    //     },0);

    //     return finalTotal
    // }
  }

  checkDynamicCondition(rowData, e: string, isFirst?, isLast?): boolean {
    if (e.includes("dataTable")) {
      if (this.dataTable) {
        return eval(e);
      } else {
        return false;
      }
    } else return eval(e);
  }
  getConditionedActions(rowData, options: any[]): any[] {
    return options.filter(
      (option) =>
        option.condition == undefined ||
        this.checkDynamicCondition(rowData, option.condition)
    );
  }
  getConditionedIconActions(rowData, options: any[], rowIndex): any[] {
    const isFirst = rowIndex == 0;
    const isLast = this.config.rowData.length == rowIndex + 1;
    return options.filter(
      (option) =>
        option.condition == undefined ||
        this.checkDynamicCondition(rowData, option.condition, isFirst, isLast)
    );
  }
  getAmountValue(number) {
    return number
      .toFixed(1)
      .toString()
      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }
  getEval(data, evalExp) {
    if (evalExp) return eval(evalExp);
    else return data;
  }
  convertEvalDataForCell(data, evalExp) {
    const val = this.getEval(data, evalExp);
    if (typeof val == "number") return val.toFixed(2);
    else return val;
  }
  mapToRowData(rowData, selectedData) {
    rowData["_selectedData"] = selectedData;
  }
  cancelRating(rowData) {
    this.ratingVal = null;
    this.popOvers.forEach((pop) => {
      pop.hide();
    });
  }
  saveRating(rowData) {
    rowData["rating"] = _.cloneDeep(this.ratingVal);
    this.ratingVal = null;
    this.onRatingChange.emit(rowData);
    this.popOvers.forEach((pop) => {
      pop.hide();
    });
  }

  getAllColField() {
    const filter = this.config.colDef.filter((row: any) => row.field);
    return filter.map((row: any) => {
      if (row.field != undefined) {
        return row.field;
      }
    });
  }
  statusFromData(rowData, selectedData) {
    this.onSelection.emit(this.selectedRows);
  }
}
