import { Component, Output, EventEmitter, ViewChild } from "@angular/core";
import { drawerConfig } from "../../dn-components/dn-drawer-panel/src/dn-drawer-panel.model";
import { Router, ActivatedRoute, NavigationStart, NavigationEnd, NavigationError,NavigationCancel } from "@angular/router";
import { DashboardFunctionalityService } from "src/services/dashboard-functionality.service";
import { drawerSearchConfig } from "src/dn-components/dn-search-drawer/src/dn-search-drawer.model";
import { AclInfo } from "./acl";
import { AclData } from './acl-data';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {Location} from '@angular/common';
import { Utility } from 'src/modules/common/Utility';
import { REMEMBER_ME, SESSION_USER_INITIALS } from 'src/services/authentication.service';
import { DnSnackbBarComponent } from 'src/dn-components/dn-snackbar/src/dn-snackbar.component';

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
  providers: [DashboardFunctionalityService, Location],
})
export class NavigationComponent {
  @Output() closeClicked: EventEmitter<any> = new EventEmitter();
  @ViewChild('snackbar', { static: false }) snackbar: DnSnackbBarComponent;
  acl_key: any;
  currentPath = '';
  mainPath = '';
  public pmr: any = {
    icon: "../../assets/images/johnson-johnson-2.svg",
  };
  public checkbox = {
    isChecked: false,
  };
  
  
  public asideNav: any;
  public sideNavConfig: any = {
    isOpen: false,
  };

  public drawerConfig: drawerSearchConfig = {
    isActive: false,
    isRightToleft: false,
  };
  public profileConfig: drawerConfig = {
    isActive: false,
    isRightToleft: false,
  };
  public notificationConfig: drawerConfig = {
    isActive: false,
    isRightToleft: false,
  };

  public recentlySelected: object = {};
  public isTileView: boolean = false;
  storageDetails: any;
  storagePercent: number = 0;
  displayUserProfile: boolean;
  message: string;
  adminCheck: boolean;
  pageLoading: boolean;

  constructor(
    public route: Router,
    private location: Location,
    public router: ActivatedRoute,
    private loader: NgxUiLoaderService,
    private dashboardFunctionalityService: DashboardFunctionalityService,
    private utility: Utility
  ) {
    route.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart:
          this.pageLoading = true;
          this.loader.startLoader('nav-loader')
          break;
        case event instanceof NavigationError:
        case event instanceof NavigationCancel:
        case event instanceof NavigationEnd:
          this.pageLoading = false;
          this.sideNavConfig.isOpen = false;
          setTimeout(()=> {
            this.loader.stopLoader('nav-loader')
          },1000)
          break;
        default:
          break;
      }
    });
  }

  //dikshit
  checkAuth(aclModule: AclInfo[], module: string): boolean {
    var access: boolean = false;
    // 
    aclModule.forEach((e) => {
      if (e.moduleName == module) {
        // 
        access = e.accessLevel;
      }
    });
    // 
    return access;
    //return true;
  }
  // modules
  checkModuleAccess(module: string): boolean {
    if (localStorage.getItem(REMEMBER_ME))
      var aclModule: AclInfo[] = JSON.parse(localStorage.getItem("aclModule"));
    else
      var aclModule: AclInfo[] = JSON.parse(sessionStorage.getItem("aclModule"));

    var access: boolean = this.checkAuth(aclModule, module);


    return access;
    //return true;
  }

  public setAsideNavClientId(initials) {
    this.asideNav['clientId'] = initials;
  }

  getCurrentPath() {
    let mainNav = null
    this.currentPath = this.route.url
    this.asideNav.mainNavConfig.forEach(element => {
      if(element.route == this.currentPath) {
        mainNav = element.label
        
      } else  if(element.list) {
        element.list.forEach(subNavDetails => {
          if(subNavDetails.route) {
            if(subNavDetails.route == this.currentPath) { 
              if(mainNav == null) {
                mainNav = element.label
              }
            }
          }          
        });
      }
    });
    this.mainPath = mainNav
    // this.currentPath = '/' + this.router.snapshot.url[0].path
  }

  ngOnInit() {
    if (localStorage.getItem('aclModule') == null) {
      localStorage.setItem('aclModule', JSON.stringify(new AclData().aclModule))
    } else {
      // This is just for demo purpose
      localStorage.removeItem('aclModule')
      localStorage.setItem('aclModule', JSON.stringify(new AclData().aclModule))
    }
    if (localStorage.getItem('roleName') == null) {
      localStorage.setItem("roleName", 'ROLE_Client Admin');
    }
    //Doubt - Should it be true ?
    if (localStorage.getItem("roleName") == "ROLE_Client Admin") {
      // this.adminCheck = false;
      this.adminCheck = true;
    } else {
      // this.adminCheck = true;
      this.adminCheck = false;
    }
    //Replace DN_CSRF
    // this.acl_key = JSON.parse(localStorage.getItem('aclModule'));
    let myNav;
    // this.getStorage();
    this.asideNav = {
      clientId: localStorage.getItem(SESSION_USER_INITIALS),
      mainNavConfig: [
        {
          label: "Dashboard",
          icon: "visualization",
          type: 0,
          route: '/dashboard',
          active: false,
          allowed: this.utility.canDisplayDashboard()
        },
        {
          label: "Manage User",
          icon: "user",
          type: 0,
          route: '/manageuser',
          active: false,
          allowed: this.utility.checkModuleAccess(["JnJ Admin"])
        },
        {
          label: "Manage Supplier",
          icon: "SRM",
          type: 0,
          route: '/manage-supplier',
          active: false,
          allowed: this.utility.checkModuleAccess(["JnJ Admin", "JnJ Super User"])
        },
        {
          label: "My Projects",
          icon: "ProjectJnJ",
          type: 0,
          route: '/project',
          active: false,
          allowed: this.utility.checkModuleAccess(["JnJ Admin", "JnJ Contact", "JnJ Super User"])
        },
        {
          label: "Supplier Projects",
          icon: "ProjectSupplier",
          type: 0,
          route: '/get/project-supplier',
          active: false,
          allowed: this.utility.checkModuleAccess(["Supplier Admin", "Supplier User"])
        },
        {
          label: "Baseline Pricing Inquiries",
          icon: "baselinePrice",
          type: 0,
          route: '/baseline-pricing/supplier',
          active: false,
          allowed: this.utility.checkModuleAccess(["Supplier Admin"])
        },
        {
          label: "Baseline Pricing Inquiries",
          icon: "rfxRequest",
          type: 0,
          route: '/rfp',
          active: false,
          allowed: this.utility.checkModuleAccess(["JnJ Admin", "JnJ Super User"])
        },
        {
          label: "My Organization",
          icon: "manage_Org",
          type: 0,
          route: '/my-organization',
          active: false,
          allowed: this.utility.checkModuleAccess(["Supplier Admin"])
          // allowed: this.checkModuleAccess("organization")
        },
        // {
        //   label: "Reports",
        //   icon: "visualization",
        //   type: 0,
        //   route: '/report',
        //   active: false,
        //   allowed: this.utility.checkModuleAccess(["JnJ Admin", "JnJ Super User"])
        // },
        // {
        //   label: "Currency Exchange",
        //   icon: "baselinePrice",
        //   type: 0,
        //   route: '/currency-exchange',
        //   active: false,
        //   allowed: this.utility.checkModuleAccess(["JnJ Admin", "JnJ Super User"])
        // },
       
      ],
      subNavConfig: [
        // {
        //   label: "Notification",
        //   icon: "notification",
        //   active: false,
        //   hasBadge: true,
        //   allowed: this.checkModuleAccess("notifications"),
        // },
        {
          label: "Setting",
          icon: "settings",
          route: "/settings",
          active: false,
          hasBadge: false,
          allowed: false
        },
        {
          label: "Help",
          icon: "help",
          route: "/help",
          active: false,
          hasBadge: false,
          allowed: true
        }
      ],
    };
    this.dashboardFunctionalityService.currentMessage.subscribe((message) => {
      this.message = message;

      if (this.message == "Profile") {
        this.userProfileBtnClicked(event);
      } else {
        if (this.message == "Administration") {
          this.message = "Administrator";
        }
        myNav = this.asideNav.mainNavConfig.filter(
          (item) => item.label === this.message
        );
        if (myNav.length == 0) {
          myNav = this.asideNav.subNavConfig.filter(
            (item) => item.label === this.message
          );
          this.subnavToggle(myNav[0]);
        } else {
          this.sidenavToggle(myNav[0]);
        }
      }
    });
    this.getCurrentPath()
  }

  public downloadFile = (content: any, fileName: string) => {
    var link = document.createElement('a');
    link.target = '_blank';
    link.href = window.URL.createObjectURL(content);
    link.download = fileName;
    link.click();
  }

//   downloadHelp() {
//     this.lockbox.downloadStaticFile('PMR_Instructions.pdf').subscribe((content) => {
//       this.downloadFile(content, 'PMR_Instructions.pdf');
//   });
// }

  subnavToggle(nav) {
    if (nav !== undefined) {
      this.profileConfig.isActive = false;
      this.notificationConfig.isActive = false;
      this.drawerConfig.isActive = false;
      this.sideNavConfig.isOpen = false;
      this.recentlySelected["active"] = false;
      if (this.recentlySelected["icon"] != nav.icon) {
        nav.active = true;
      }
      
      if (nav.route) {
        this.route.navigate([nav.route]);
      } else if (nav.label == "Notification") {
        if (
          this.recentlySelected["icon"] == nav.icon &&
          this.notificationConfig.isActive
        ) {
          this.notificationConfig.isActive = false;
          if (this.displayUserProfile) {
            this.displayUserProfile = false;
          } else if (this.drawerConfig.isActive) {
            this.drawerConfig.isActive = false;
          }
        } else {
          this.notificationConfig.isActive = true;
        }
      } 
      // else if (nav.label == "Help") {
      //   this.downloadHelp();
      // }

      if (nav.label == "Search") {
        this.drawerConfig.isHalfWidth = true;
      } 
      this.recentlySelected = nav;
    }
  }

  sidenavToggle(nav) {
    this.drawerConfig.isHalfWidth = false;
    this.profileConfig.isActive = false;
    this.notificationConfig.isActive = false;
    this.recentlySelected["active"] = false;
    if (this.recentlySelected["icon"] != nav.icon) {
      nav.active = true;
    }
    if (nav.type == 1) {
      this.drawerConfig.isActive = false;
      if (
        this.recentlySelected["icon"] == nav.icon &&
        this.sideNavConfig.isOpen
      ) {
        this.sideNavConfig.isOpen = false;
      } else {
        this.sideNavConfig["listConfig"] = nav.list;
        this.sideNavConfig.isOpen = true;
      }
    } else if (nav.type == 0) {
      this.route.navigate([nav.route]);
      this.closeDrawer();
    } else {
      this.drawerConfig.isHalfWidth = true;
      this.sideNavConfig.isOpen = false;
      if (
        this.recentlySelected["icon"] == nav.icon &&
        this.drawerConfig.isActive
      ) {
        this.drawerConfig.isActive = false;
        if (this.displayUserProfile) {
          this.displayUserProfile = false;
        }
      } else {
        this.drawerConfig.isActive = true;
      }
    }

    this.recentlySelected = nav;
  }

  getDelay(i) {
    return 0.1 * i + "s";
  }

  closeDrawer() {
    this.drawerConfig.isActive = false;

    if (this.displayUserProfile) {
      this.displayUserProfile = false;
    } else if (this.notificationConfig.isActive) {
      this.notificationConfig.isActive = false;
    }
  }
  profileDrawerClick(type?) {
    this.profileConfig.isActive = false;
 
    if (type == 'error')
      this.utility.showNotification('Unknown exception occured','error');
    if (type == 'error403')
      this.utility.sessionExpiredNotification();
  }
  closeClick() {
    this.closeClicked.emit();
  }

  closeSecondaryDrawer() {
    this.sideNavConfig.isOpen = false;
  }
  userProfileBtnClicked(evt) {
    this.drawerConfig.isActive = false;
    this.notificationConfig.isActive = false;
    this.recentlySelected["active"] = false;
    this.sideNavConfig.isOpen = false;

    if (this.profileConfig.isActive) {
      this.profileConfig.isActive = false;
    } else {
      this.profileConfig.isActive = true;

      this.closeClick();
    }
  }
  
  goToDashboard() {
    alert("Test");
  }
}
