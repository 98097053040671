import { NgModule } from "@angular/core";
import { DnNotificationDrawerComponent } from './dn-notification-drawer.component';
import { MatIconModule } from '@angular/material';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [ CommonModule, MatIconModule ],
    declarations: [ DnNotificationDrawerComponent ],
    exports: [ DnNotificationDrawerComponent ]
})

export class DnNotificationDrawerModule {
    constructor(){
        
    }
}