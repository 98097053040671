import { Component, Input, Output, EventEmitter, OnChanges } from "@angular/core";
import { SelectItem } from 'primeng/api';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';

@Component({
    selector: 'dn-list-box',
    templateUrl: './dn-list-box.component.html',
    styleUrls: ['./dn-list-box.component.scss']
})

export class DnListBoxComponent implements OnChanges {

    @Input() Data: any[];
    @Input() single: boolean;
    @Input() isSelectAllHide: boolean;
    @Input() addNew: boolean;
    @Input() addNewFor: string;
    @Output() onAddOptions: EventEmitter<any> = new EventEmitter();
    @Output() onSelectionChange: EventEmitter<any> = new EventEmitter();
    cities1: SelectItem[];
    form: FormGroup;
    roleId = 1;

    @Input() selected: any[] = [];
    selectedSingle: any;

    constructor(private fb: FormBuilder) {
        this.form = fb.group({
            options: fb.array([])
        })
    }
    ngOnChanges() {
    }
    addNewOptionGroup() {
        const add = this.form.get('options') as FormArray;
        add.push(this.fb.group({
            name: []
        }))
    }
    deleteOptionGroup(index: number) {
        const add = this.form.get('options') as FormArray;
        add.removeAt(index)
    }

    changeForm() {
        this.onAddOptions.emit(this.form.value);
    }

    onSelection() {
        this.onSelectionChange.emit(this.selected);
    }

    public onReset() {
        this.selected = [];
    }

    ngOnInit() {

    }

}