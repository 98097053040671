import { Component, Input, Output, EventEmitter } from "@angular/core";
import { drawerConfig } from './dn-drawer-panel.model';

@Component({
    selector: 'dn-drawer-panel',
    templateUrl: './dn-drawer-panel.component.html',
    styleUrls: ['./dn-drawer-panel.component.scss']
})

export class DnDrawerPanelComponent {

    @Input() drawerConfig:drawerConfig;
    @Output() closedDrawer: EventEmitter<any> = new EventEmitter();

    constructor(){

    }

    ngOnInit(){ }

    closeDrawer(){
        this.drawerConfig.isActive = false;
        this.closedDrawer.emit();
    }
}
