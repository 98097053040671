import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { MatIconModule, MatMenuModule, MatTooltipModule, MatSnackBarModule } from '@angular/material';
import { DnSnackbBarComponent } from './dn-snackbar.component';
import { DnFormCheckboxModule } from '../../dn-form-checkbox/src/package.module';


@NgModule({
    imports: [ CommonModule, MatIconModule, MatSnackBarModule ],
    declarations: [ DnSnackbBarComponent ],
    exports: [ DnSnackbBarComponent ]
})


export class DnSnackBarModule{ }