import { Component, Output, EventEmitter, ElementRef, ViewChild, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { ITextConfig } from '../../dn-components/dn-form-text/src/dn-form-text.model';
import { SearchService } from 'src/services/search.service';
import { IFilterSelectConfig } from 'src/dn-components/dn-filter-select/src/dn-filter-select.model';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatDialog } from '@angular/material';
import { ManageUserService } from 'src/services/manage-user.service';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { InfoDialogComponent } from 'src/dn-components/dn-info-notification/src/info-dialog.component';
import * as _moment from 'moment';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { DnDialogPreviewComponent } from 'src/dn-components/dn-dialog-preview/src/dn-dialog-preview.component';
import { DomSanitizer } from '@angular/platform-browser';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

export const MY_CUSTOM_FORMATS = {
    parseInput: 'LL LT',
    fullPickerInput: 'LL LT',
    datePickerInput: 'LL',
    timePickerInput: 'LT',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
};

@Component({
    selector: 'search-panel',
    templateUrl: './search-panel.component.html',
    styleUrls: ['./search-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }]

})
export class SearchPanelComponent implements OnInit {
    query: any;
    showClearBtn: boolean = false;
    previewFile: any;
    detailView = false;
    userCtrl = new FormControl();
    dateTimeRange = new moment();
    @ViewChild('userInput', { static: false }) userInput: ElementRef<HTMLInputElement>;
    allUsers: any[] = [];
    filteredUsers: Observable<any[]>;
    public filterSearch = {
        query: "",
        result_count: 10,
        categories: [],
        tags: [],
        uploadedBy: [],
        uploadedAfter: new Date().toUTCString(),
        uploadedBefore: new Date().toUTCString(),
        categoryFilter: "",
        tagFilter: ""
    }
    @Output() closeClicked: EventEmitter<any> = new EventEmitter();
    showFilters: boolean = false;
    public selecteModel = 'all-0';

    public textConfig: ITextConfig = {
        fieldKey: 'searchedText',
        attributes: {
            placeholder: 'Search',
            appearance: 'fill',
            prefixIcon: 'search',
            class: 'searchField'
        }
    };
    public autoSuggestConfig: ITextConfig = {
        fieldKey: 'autoSuggestText',
        attributes: {
            placeholder: 'Auto Suggest field',
            appearance: 'fill',
        }
    };
    public uploadDateConfig = {
        uploadedAfter: {
            fieldKey: 'uploadedAfter',
            attributes: {
                type: 'text',
                appearance: 'fill',
                disable: false,
                // class: 'col-xs-4',
                isMandatory: false,
                maxDate: new Date()
            }
        },
        uploadedBefore: {
            fieldKey: 'uploadedBefore',
            attributes: {
                type: 'text',
                appearance: 'fill',
                disable: false,
                // class: 'col-xs-4',
                isMandatory: false,
                maxDate: new Date()
            }
        },
    };
    public commonModel = {
        selectedCategory: "",
        searchedText: ""
    }
    public categoryFilter: IFilterSelectConfig = {
        fieldKey: 'categoryFilter',
        dataKey: 'name',
        returnKey: 'value',
        options: [
            { name: 'Include Only', value: 'include' },
            { name: 'Exclude Only', value: 'exclude' },
        ],
        attributes: {
            label: '--',
            appearance: 'fill',
            class: 'z10 col-xs-6 float-initial alignSelectBox',
            // label: ''
        }
    };
    public tagFilter: IFilterSelectConfig = {
        fieldKey: 'tagFilter',
        dataKey: 'name',
        returnKey: 'value',
        options: [
            { name: 'Include Only', value: 'include' },
            { name: 'Exclude Only', value: 'exclude' },
        ],
        attributes: {
            label: '--',
            appearance: 'fill',
            class: 'z10 col-xs-6 float-initial alignSelectBox',
            // label: ''
        }
    };
    testArray: Observable<any[]>;
    chipsSelections = [];
    categoryOptions: any;
    tagOptions: { label: string; value: string; }[];
    selectionVisibility = {
        category: false,
        tags: false
    };
    //model = ""
    constructor(
        private searchService: SearchService,
        public dialog: MatDialog,
        private manageUserService: ManageUserService,
        public domSanitizer: DomSanitizer
    ) {
        this.filteredUsers = this.userCtrl.valueChanges
            .pipe(
                startWith(''),
                map(value => this._filter(value))
            );
    }
    selection(type, evt) {
        if (type == 'category') {
            this.filterSearch.categories = evt;
        } else if (type == 'tags') {
            this.filterSearch.tags = evt;
        }
    }
    checkAlreadyExist(arr, val) {
        return arr.some((arrVal) => {
            return val === arrVal;
        });
    }

    mediaURL(item) {
        // return this.domSanitizer.bypassSecurityTrustResourceUrl('https://docs.google.com/a/' + this.getDomainName(item) +'/viewer?url=' + item);
        return this.domSanitizer.bypassSecurityTrustResourceUrl('https://view.officeapps.live.com/op/embed.aspx?src=' + item);
    }
    getDomainName(path) : string{
        const url = document.createElement('a');
        //  Set href to any path
        url.setAttribute('href', path);
        return url.hostname.replace('www.','')
    }
    selected(event: MatAutocompleteSelectedEvent): void {
        if (!this.checkAlreadyExist(this.chipsSelections, event.option.value)) {
            this.chipsSelections.push(event.option.value);
        }
        this.userInput.nativeElement.value = '';
        this.userCtrl.setValue(null);
    }
    private _filter(value: any): any[] {
        const filterValue = value;
        return this.allUsers.filter(option => option.viewValue.toLowerCase().includes(filterValue));
    }
    getAllUser() {
        this.manageUserService.getAllUser().subscribe(
            (data) => {
                data.map(
                    item => {
                        this.allUsers.push({ value: item.id, viewValue: item.userName });
                    }
                );
            }
        );
    }
    configChange(type, e) {
        if (type == 'category') {
            if (e.value != '') {
                this.selectionVisibility.category = true;
            } else {
                this.selectionVisibility.category = false;
            }
        } else if (type == 'tags') {
            if (e.value != '') {
                this.selectionVisibility.tags = true;
            } else {
                this.selectionVisibility.tags = false;
            }
        }
    }
    // getAllFiles() {
    //     this.testArray = this.searchService.getSearchResults();
    // }
    searchKeyUp(evt) {
        this.testArray = this.searchService.getSearchResults().pipe(
            map(result => result.filter(item => (JSON.stringify(item).toLowerCase().includes(this.commonModel.searchedText.toLowerCase()))))
        );
    }

    print() {
    }

    download(link) {
        // Actual Source Link
        var tag = document.createElement('a');
        tag.href = link;
        tag.download = 'download';
        tag.click();
    }

    preview(item) {
        this.detailView = true;
        this.previewFile = item;

    }
    fullView() {
        const dialogRef = this.dialog.open(DnDialogPreviewComponent, {
            width: '100vw',
            height: '100vh',
            maxWidth: '100vw',
            autoFocus: true,
            data: {
                file: this.previewFile.file,
                path: this.previewFile.path
            }
        });
        dialogRef.afterClosed().subscribe(() => {
            // this.router.navigate(['/dashboard']);
        });
    }
    toggleFilter() {
        this.showFilters = !this.showFilters;
    }
    closeClick() {
        this.closeClicked.emit();
    }
    apiCallBackFunction(msg = 'Call the Api Service'): any {
        this.filterSearch.query = this.commonModel.searchedText;
        this.filterSearch.uploadedAfter = new Date(this.filterSearch.uploadedAfter).toUTCString();
        this.filterSearch.uploadedBefore = new Date(this.filterSearch.uploadedBefore).toUTCString();
        this.chipsSelections.map(
            item => {
                this.filterSearch.uploadedBy.push(item.value);
            }
        );
        this.toggleFilter();

        this.showClearBtn = true;
        // this.searchService.searchFilter(this.filterSearch).subscribe(
        //     data => {
        //         this.toggleFilter();
        //         this.getAllFiles();
        //     }
        // );
        const dialogRef = this.dialog.open(InfoDialogComponent, {
            width: '620px',
            height: '326px',
            autoFocus: false,
            data: msg
        });
        dialogRef.afterClosed().subscribe(() => {
            // this.router.navigate(['/dashboard']);
        });
    }

    resetFilter() {
        this.filterSearch = {
            query: "",
            result_count: 10,
            categories: [],
            tags: [],
            uploadedBy: [],
            uploadedAfter: new Date().toUTCString(),
            uploadedBefore: new Date().toUTCString(),
            categoryFilter: "",
            tagFilter: ""
        }
        this.chipsSelections = [];
        this.toggleFilter();
    }

    ngOnInit() {


        // this.categoryOptions = [
        //     { label: 'Category Name -01', value: 'Category Name -01' },
        //     { label: 'Category Name -02', value: 'Category Name -02' },
        //     { label: 'Category Name -03', value: 'Category Name -03' },
        // ];
        // this.tagOptions = [
        //     { label: 'Tags Name -01', value: 'Tags Name -01' },
        //     { label: 'Tags Name -02', value: 'Tags Name -02' },
        //     { label: 'Tags Name -03', value: 'Tags Name -03' },
        // ];
        this.getAllUser();
        // this.getAllFiles();
    }
}