import { Component } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { NotificationDialogComponent } from 'src/dn-components/dn-notification-dialog/src/notification-dialog.component';
import { AuthenticationService } from 'src/services/authentication.service';
import { drawerConfig } from '../dn-components/dn-drawer-panel/src/dn-drawer-panel.model';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'Project1';
    public checkbox = {
        isChecked: false
    };

    public asideNav: any;
    public sideNavConfig: any = {
        isOpen: false
    }
    public drawerConfig: drawerConfig = {
        isActive: false,
        isRightToleft: false
    };
    public recentlySelected: object = {};
    public isTileView: boolean = false;
    ssoCode = "";
    constructor(
        private authService: AuthenticationService,
        private router: Router,
        public dialog: MatDialog,) { }

    ngOnInit() {
        let url = window.location.href;
        let newUrl = new URL(url);
        let code = newUrl.searchParams.get("code");
        localStorage.removeItem('loginprogress')
        if (code) {
            localStorage.setItem('loginprogress', 'true');
             this.authService.authenticateSsoCode(code).subscribe(
                data => {
                    this.router.navigate(['/landing-page']);
                },
                error => {
                    this.openDialog(
                        'warning',
                        "This url is valid but you are not authorized for this content. Please go to https://iam.jnj.com to raise the group member request.",
                        [
                            {
                                name: 'Close',
                                class: 'primary-btn',
                            },

                        ]
                    );
                }
            );
        }

        this.asideNav = {
            clientId: 'AK',
            mainNavConfig: [
                {
                    icon: 'search',
                    type: 2,
                    active: false
                },
                {
                    icon: 'add_box',
                    type: 1,
                    active: false,
                    list: [
                        {
                            title: "create organisation",
                            active: false
                        },
                        {
                            title: "create user",
                            active: false
                        },
                        {
                            title: "create user group",
                            active: false
                        },
                        {
                            title: "create business unit",
                            active: false
                        },
                        {
                            title: "create department",
                            active: false
                        },
                        {
                            title: "create masters",
                            active: false
                        }
                    ]
                },
                {
                    icon: 'supervised_user_circle',
                    type: 1,
                    active: false,
                    list: [
                        {
                            title: "manage organisation",
                            active: false
                        },
                        {
                            title: "manage user",
                            active: false
                        },
                        {
                            title: "manage business unit",
                            active: false
                        },
                        {
                            title: "manage department",
                            active: false
                        },
                        {
                            title: "manage masters",
                            active: false
                        },
                        {
                            title: "manage notifications",
                            active: false
                        }
                    ]
                },
                {
                    icon: 'folder',
                    type: 2,
                    active: false
                },
                {
                    icon: 'question_answer',
                    type: 2,
                    active: false
                }
            ],
            subNavConfig: [
                {
                    icon: 'view_module',
                    active: false
                },
                {
                    icon: 'view_module',
                    active: false
                },
                {
                    icon: 'view_module',
                    active: false
                },
                {
                    icon: 'view_module',
                    active: false
                }
            ]
        };
    }

    sidenavToggle(nav) {
        this.recentlySelected['active'] = false;
        if (this.recentlySelected['icon'] != nav.icon) {
            nav.active = true;
        }
        if (nav.type == 1) {
            this.drawerConfig.isActive = false;
            if (this.recentlySelected['icon'] == nav.icon && this.sideNavConfig.isOpen) {
                this.sideNavConfig.isOpen = false;
            }
            else {
                this.sideNavConfig['listConfig'] = nav.list;
                this.sideNavConfig.isOpen = true;
            }
        }
        else {
            this.sideNavConfig.isOpen = false;
            if (this.recentlySelected['icon'] == nav.icon && this.drawerConfig.isActive) {
                this.drawerConfig.isActive = false;
            }
            else {
                this.drawerConfig.isActive = true;
            }
        }
        this.recentlySelected = nav;
    }

    getDelay(i) {
        return 0.10 * i + 's';
    }

    closeDrawer() {
        this.drawerConfig.isActive = false;
    }
    openDialog(type, message, actions, data?, e?) {
        const dialogRef = this.dialog.open(NotificationDialogComponent, {
            width: '620px',
            height: '326px',
            autoFocus: false,
            data: {
                type: type, // error, success, information, delete, warning
                message: message, //'Login Failed',
                actions: actions,
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            // below are the logic for different buttons
            switch (result) {

                case 'no':
                    //logic for no
                    break;
            }
        });
    }
}
