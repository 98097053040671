import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { MatIconModule, MatMenuModule, MatCheckboxModule, MatButtonModule, MatTabsModule } from '@angular/material';
import { FormsModule } from '@angular/forms';
import { NotificationViewComponent } from './notification-view.component';
import { DnFormTextModule } from 'src/dn-components/dn-form-text/src/package.module';
import { DnFilterSelectModule } from 'src/dn-components/dn-filter-select/src/package.module';
import { DnListBoxModule } from 'src/dn-components/dn-list-box/src/package.module';
import { DnFormDateModule } from 'src/dn-components/dn-form-date/src/package.module';
import { DnSnackBarModule } from 'src/dn-components/dn-snackbar/src/package.module';
// import {MultiSelectModule} from 'primeng/primeng'
@NgModule({
    imports: [
        CommonModule, 
        MatCheckboxModule, 
        MatButtonModule, 
        MatMenuModule, 
        MultiSelectModule, 
        FormsModule, 
        TableModule, 
        MatIconModule, 
        MatTabsModule,
        DnFormTextModule,
        DnFilterSelectModule,
        DnListBoxModule,
        DnFormDateModule,
        DnSnackBarModule
    ],
    declarations: [NotificationViewComponent],
    exports: [NotificationViewComponent]
})

export class NotificationViewModule { }