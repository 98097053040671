import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { MatIconModule, MatInputModule } from '@angular/material';
import { DnFormTextareaComponent } from './dn-form-textarea.component';
import { DnValidationModule } from '../../dn-validation/src/package.module';

@NgModule({
    imports: [ CommonModule, MatIconModule, MatInputModule, DnValidationModule],
    declarations: [ DnFormTextareaComponent ],
    exports: [ DnFormTextareaComponent ]
})

export class DnFormTextareaModule { }