import {
    Directive,
    HostBinding,
    HostListener,
    Output,
    EventEmitter,
    Input,
    ElementRef,
    Renderer2
  } from "@angular/core";
  import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

export interface FileHandle {
    file: File,
    url: SafeUrl,
    isUploaded: boolean
  }
  
  @Directive({
    selector: "[appDrag]"
  })
  export class DragDirective {
    @Input() accept: string;
    @Input() files: FileHandle[];
    @Output() onFilesDroppped: EventEmitter<FileHandle[]> = new EventEmitter();
  
    constructor(private elemRef:ElementRef, private renderer:Renderer2, private sanitizer: DomSanitizer) { }
  
    @HostListener("dragover", ["$event"]) public onDragOver(evt: DragEvent) {
      evt.preventDefault();
      evt.stopPropagation();
      this.renderer.setStyle(this.elemRef.nativeElement, 'background', '#ccc');
    }
  
    @HostListener("dragleave", ["$event"]) public onDragLeave(evt: DragEvent) {
      evt.preventDefault();
      evt.stopPropagation();
      this.renderer.setStyle(this.elemRef.nativeElement, 'background', '#e1e1e1');
    }
  
    @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
      evt.preventDefault();
      evt.stopPropagation();
      this.renderer.setStyle(this.elemRef.nativeElement, 'background', '#e1e1e1');
      let AllowedFiles = []
      let isAllowedFile = false
      if(this.accept) {
        AllowedFiles = this.accept.split(',')
      } else {
        isAllowedFile = true
      }
      for (let i = 0; i < evt.dataTransfer.files.length; i++) {
          
        AllowedFiles.every(element => {
          if(evt.dataTransfer.files[i].name.includes(element)) {
            isAllowedFile= true;
            return false
          } else {
            return true
          }
        });
        if(isAllowedFile) {
          const file = evt.dataTransfer.files[i];
          const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
          const isUploaded = false;
          this.files.push({ file, url, isUploaded });
        }
        
      }

      if (this.files.length > 0) {
        this.onFilesDroppped.emit();
      }
    }
  }