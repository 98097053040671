import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { MatInputModule, MatDatepickerModule, MatNativeDateModule } from '@angular/material';
import { DnFormDateComponent } from './dn-form-date.component';
import { DnValidationModule } from '../../dn-validation/src/package.module';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [ CommonModule, FormsModule, MatInputModule, MatDatepickerModule, MatNativeDateModule, DnValidationModule],
    providers: [ MatDatepickerModule ],
    declarations: [ DnFormDateComponent ],
    exports: [ DnFormDateComponent ]
})

export class DnFormDateModule { }