import { Component, Input, Output, EventEmitter } from "@angular/core";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileHandle } from './drag-drop.directive';

@Component({
    selector: 'dn-drag-drop-upload',
    templateUrl: './dn-drag-drop-upload.component.html',
    styleUrls: ['./dn-drag-drop-upload.component.scss']
})

export class DnDragDropUploadComponent {

    @Input() files: FileHandle[] = [];
    @Input() height: number;
    @Input() accept: string;
    @Output() onFilesDroppped: EventEmitter<FileHandle[]> = new EventEmitter();
    width: number;
    subscribeTimer: number;
    timeLeft = 0;
    interval: any;

    constructor(private sanitizer: DomSanitizer){ }

    filesDropped(evt): void {
        this.startTimer();
        for (let i = 0; i < evt.target.files.length; i++) {
            const file = evt.target.files[i];
            const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
            const isUploaded = false;
            this.files.push({ file, url, isUploaded });
        }


        if (this.files.length > 0) {
            this.onFilesDroppped.emit(evt);
        }
        this.onFilesDroppped.emit(evt);
    }

    handleFileInput(evt){
        this.startTimer();
        for (let i = 0; i < evt.target.files.length; i++) {
            const file = evt.target.files[i];
            
            const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
            const isUploaded = false;
            this.files.push({ file, url, isUploaded });
        }


        if (this.files.length > 0) {
            this.onFilesDroppped.emit(evt);
        }
        evt.target.value = null
    }

    removeFile(file) {
      
      this.files.splice(this.files.indexOf(file), 1);
    }

    startTimer() {
        this.interval = setInterval(() => {
          if(this.timeLeft < 100) {
            this.timeLeft++;
          } else {
            clearInterval(this.interval);
          }
        },30)
      }

    upload(): void {
        //get image upload file obj;
    }
}