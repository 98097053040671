import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EndPointConfig } from 'src/app/_config/endpoint.config';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SearchService {

    baseUrl: string;
    constructor(private httpClient: HttpClient) {
        // this.baseUrl = new EndPointConfig().baseUrl;
        this.baseUrl = './../assets/json/04_Search/';
    }


    //GET 
    getSearchResults(): Observable<any[]> {
        return this.httpClient.get<any[]>(this.baseUrl + 'search_results.json');
    }

    //POST
    searchFilter(filterSearch): Observable<any> {
        return this.httpClient.post<any>(`${this.baseUrl}search_filter.json`, filterSearch);
    }



}

