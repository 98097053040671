import { Component, Input, Output, EventEmitter, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { InfoDialogComponent } from 'src/dn-components/dn-info-notification/src/info-dialog.component';
import { MatDialog } from '@angular/material';
import { GlobalService } from 'src/services/global.service';
@Component({
    selector: 'change-pass',
    templateUrl: './change-pass.component.html',
    styleUrls: ['./change-pass.component.scss']
})

export class ChangePassComponent implements OnInit, OnChanges, OnDestroy {
    @Output() changePasswordView = new EventEmitter<number>();
    @Output() cancel = new EventEmitter();
    viewValue = 0;
    @Input() changePassModel = {
        currentPassword: '',
        newPassword: '',
        verifyPassword: ''
    }
    public changePasswordConfig = {
        currentPassword: {
            fieldKey: 'currentPassword',
            attributes: {
                type: 'text',
                label: 'Current password',
                appearance: 'fill',
                isMandatory: true
            }
        }, newPassword: {
            fieldKey: 'newPassword',
            attributes: {
                type: 'password',
                label: 'New password',
                appearance: 'fill',
                isMandatory: true,
                pattern: 'passwordOnly',
                rules: [
                    {
                        rule: "new RegExp('[a-z]').test(this.dataModel[this.config.fieldKey]) == false",
                        message: 'Password must contain a lower case character'
                    },
                    {
                        rule: "new RegExp('[A-Z]').test(this.dataModel[this.config.fieldKey]) == false",
                        message: 'Password must contain an upper case character'
                    },
                    {
                        rule: "this.dataModel[this.config.fieldKey].length < 6",
                        message: 'Password must be atleast 6 characters long'
                    },
                ]
            }
        }, verifyPassword: {
            fieldKey: 'verifyPassword',
            attributes: {
                type: 'password',
                label: 'Verify password',
                appearance: 'fill',
                isMandatory: true,
                rules: [
                    {
                        rule: "this.dataModel['newPassword'] != this.dataModel[this.config.fieldKey]",
                        message: 'Password not match'
                    }
                ]
            }
        }
    }

    @Output() onButtonClicked: EventEmitter<any> = new EventEmitter();

    constructor(
        public dialog: MatDialog,
        public globalService: GlobalService,
    ) { }

    sendMessageToParent(message: number) {
        this.changePasswordView.emit(message)
    }

    btnClicked(evt, btnType) {
        evt['btnType'] = btnType;
        this.onButtonClicked.emit(evt);
    }

    ngOnChanges(e) {
        
    }

    ngOnInit() {
        // Step 1
        let mandatoryFields = Object.values(this.changePasswordConfig).filter(
            (key) =>
                (key.attributes.isMandatory)
        );
        this.globalService.validatorObject = mandatoryFields;
    }

    ngOnDestroy() {
        this.globalService.validatorObject = [];
        this.globalService.errorObject = [];
    }

    closeCall() {
        this.cancel.emit();
    }

    apiCallBackFunction(msg = 'Call the Api Service'): any {
        const dialogRef = this.dialog.open(InfoDialogComponent, {
            width: '620px',
            height: '326px',
            autoFocus: false,
            data: msg
        });

        dialogRef.afterClosed().subscribe(result => {
            // this.router.navigate(['/dashboard']);
        });
    }

    clearVerifyPassword (event) {
        this.changePassModel.verifyPassword = '';
        this.changePassModel = {...this.changePassModel};
    }

}