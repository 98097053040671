import { Component, Input, Output, EventEmitter, OnInit, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ManageUserService } from 'src/services/manage-user.service';
import { InfoDialogComponent } from 'src/dn-components/dn-info-notification/src/info-dialog.component';
import { MatDialog } from '@angular/material';
import { GlobalService } from 'src/services/global.service';
import csc from 'country-state-city'
import _ from 'lodash';
import { Utility } from 'src/modules/common/Utility';
import { AUTHENTICATED_USER, AuthenticationService, SESSION_USER, SESSION_USER_INITIALS } from 'src/services/authentication.service';
import { DnSnackbBarComponent } from 'src/dn-components/dn-snackbar/src/dn-snackbar.component';
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import { NavigationComponent } from 'src/app/navigation/navigation.component';

@Component({
    selector: 'user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss']
})

export class UserProfileComponent implements OnInit, OnChanges, OnDestroy {
    @Output() btnClick: EventEmitter<any> = new EventEmitter();
    @ViewChild('snackbar', { static: false }) snackbar: DnSnackbBarComponent;
    tabIndex = 0
    editMode = false;
    user;
    userModel;
    passwordModel = {
        currentPassword: '',
        newPassword: '',
        verifyPassword: ''
    }
    public generalConfig = {
        firstName: {
            fieldKey: 'firstName',
            attributes: {
                type: 'text',
                label: 'First Name',
                appearance: 'fill',
                isMandatory: true,
                class: 'col-xs-6',
                rules: [
                    {
                        // rule: "this.dataModel[this.config.fieldKey].length > 10",
                        // message: 'Length should not be more that 10'
                    }
                ]
            }
        },
        lastName: {
            fieldKey: 'lastName',
            attributes: {
                label: 'Last Name',
                appearance: 'fill',
                isMandatory: true,
                class: 'col-xs-6',
            }
        },
        designation: {
            fieldKey: 'designation',
            attributes: {
                label: 'Job Title',
                appearance: 'fill',
                isMandatory: true
            }
        },
        // birthDate: {
        //     fieldKey: 'dateOfBirth',
        //     attributes: {
        //         label: 'Date of Birth',
        //         placeholder: 'MM/DD/YYYY',
        //         appearance: 'fill',
        //         isMandatory: false,
        //         class: 'iconCorrection col-xs-6',
        //         maxDate: new Date()
        //     }
        // },
        contact: {
            fieldKey: 'contactNumber',
            attributes: {
                type: 'text',
                label: 'Phone Number',
                appearance: 'fill',
                isMandatory: true,
                class: 'col-xs-6'
            }
        },
        email: {
            fieldKey: 'email',
            attributes: {
                label: 'Email',
                appearance: 'fill',
                isMandatory: true
            }
        },
        city: {
            fieldKey: 'city',
            dataKey: 'name',
            options: [],
            returnKey: 'id',
            attributes: {
                label: 'City',
                appearance: 'fill',
                class: 'col-xs-6',
                isMandatory: false,
                errorMessage: 'Choose City'
            }
        },
        state: {
            fieldKey: 'state',
            dataKey: 'name',
            returnKey: 'id',
            options: [],
            attributes: {
                label: 'State',
                appearance: 'fill',
                isMandatory: true,
                class: 'col-xs-6',
                errorMessage: 'Choose State'
            }
        },
        country: {
            fieldKey: 'country',
            dataKey: 'name',
            returnKey: 'id',
            options: [],
            attributes: {
                label: 'Country',
                appearance: 'fill',
                isMandatory: true,
                class: 'col-xs-6',
                errorMessage: 'Choose Country'
            }
        },
    }

    canChangePassword = false;

    constructor(private manageUserService: ManageUserService, private utility: Utility,
        private navigationComponent: NavigationComponent,
        private authenticationService: AuthenticationService,
        public globalService: GlobalService) { }

    ngOnInit() {
        this.getProfileDetails();

        this.canChangePassword = this.utility.allowChangePassword();
    }

    ngOnChanges(e) {
        let mandatoryFields
        let elementCheck = this.userModel;

        mandatoryFields = Object.values(this.generalConfig).filter(
            (key) =>
                (key.attributes.isMandatory && (elementCheck[key.fieldKey] == undefined || elementCheck[key.fieldKey] == null))
        );

        this.globalService.validatorObject = mandatoryFields;
    }

    ngOnDestroy() {
        this.globalService.validatorObject = [];
        this.globalService.errorObject = [];
    }

    getProfileDetails() {
        this.getAllCountry();
        this.manageUserService.getUserProfile(localStorage.getItem(SESSION_USER)).subscribe(
            response => {
                response['photo'] = this.utility.imageFromDb(response['photo']);
                response['countryText'] = csc.getCountryById(response['country'])['name'];
                response['stateText'] = csc.getStateById(response['state'])['name'];
                response['cityText'] = csc.getCityById(response['city'])['name'];

                if (response.country) {
                    this.generalConfig.state.options = csc.getStatesOfCountry(response.country)
                    if (response.state) {
                        this.generalConfig.city.options = csc.getCitiesOfState(response.state)
                    }
                }

                this.userModel = _.cloneDeep(response);
                let mandatoryFields
                let elementCheck = this.userModel;
                mandatoryFields = Object.values(this.generalConfig).filter(
                    (key) =>
                        (key.attributes.isMandatory && (elementCheck[key.fieldKey] == undefined || elementCheck[key.fieldKey] == null))
                );

                this.globalService.validatorObject = mandatoryFields;
                this.user = _.cloneDeep(response);
            },
            error => {
                if (error.status == 403)
                    this.btnClicked('error403');
                else
                    this.btnClicked('error');
            }
        );
    }

    btnClicked(type?) {
        switch (type) {
            case 'close':
                this.btnClick.emit(type);
                break;
            case 'error':
                this.btnClick.emit(type);
                break;
            case 'error403':
                this.btnClick.emit(type);
                break;
            case 'edit':
                this.editMode = true;
                break;
            case 'cancel':
                this.userModel = _.cloneDeep(this.user)
                this.editMode = false;
                break;
            case 'save':
                if (this.tabIndex != 1) {
                    this.userModel['name'] = `${this.userModel['firstName']} ${this.userModel['lastName']}`;
                    this.userModel['password'] = "default";
                    this.userModel['lastModifiedOn'] = new Date();
                    this.userModel['lastModifiedBy'] = localStorage.getItem(SESSION_USER);
                    this.userModel['photo'] = this.utility.imageToDb(this.userModel['photo']);

                    this.manageUserService.editUserProfile(this.userModel).subscribe(
                        reponse => {
                            this.snackbar.openSnackbar(reponse['msg']);
                            this.getProfileDetails();
                            localStorage.setItem(SESSION_USER_INITIALS, `${this.userModel['firstName'][0]}${this.userModel['lastName'][0]}`)
                            this.navigationComponent.ngOnInit();
                            this.btnClick.emit('close')
                        },
                        error => {
                            this.utility.showNotification('Unknown exception occured', 'error');
                        }
                    )
                }

                else {
                    let changePasswordData = {}
                    if (this.passwordModel['currentPassword'] == ''
                        || this.passwordModel['newPassword'] == ''
                        || this.passwordModel['verifyPassword'] == '')
                        this.utility.showNotification('Please input all the three fields', 'error')
                    else if (this.passwordModel['newPassword'] != this.passwordModel['verifyPassword'])
                        this.utility.showNotification('Password do not match', 'error')
                    else {
                        changePasswordData['oldPassword'] = this.passwordModel['currentPassword'];
                        changePasswordData['newPassword'] = this.passwordModel['newPassword'];
                        changePasswordData['userId'] = this.userModel['id'];
                        this.manageUserService.changePassword(changePasswordData).subscribe(
                            response => {
                                this.snackbar.openSnackbar(response['msg']);
                                this.navigationComponent.ngOnInit();
                                this.btnClick.emit('close')
                            },
                            error => {
                                this.utility.showNotification('Old password doesn\'t match', 'error');
                            }
                        )
                    }
                }
                break;
            default:
                break;


        }
    }

    indexChange(event) {
        this.tabIndex = event.index
    }

    onFileChange(event) {
        const reader = new FileReader();
        if (event.target.files && event.target.files.length) {
            const [file] = event.target.files;
            reader.readAsDataURL(file);

            // debugger
            if (file.type == 'image/jpeg') {
                reader.onload = () => {
                    this.userModel['photo'] = reader.result as string;
                };
            }


        }
    }

    changePassword(evt) {
    }
    getAllCountry() {
        // this.countries = csc.getAllCountries();
        this.generalConfig.country.options = csc.getAllCountries()
    }
    getStatesOfCountry(id) {
        this.generalConfig.state.options = csc.getStatesOfCountry(id);
    }
    getCitiesOfState(id) {
        this.generalConfig.city.options = csc.getCitiesOfState(id);
    }

    signout() {
        this.authenticationService.userLogout(localStorage.getItem(SESSION_USER)).subscribe(
        );
    }
}