import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { MatIconModule, MatInputModule } from '@angular/material';
import { DnFormTextComponent } from './dn-form-text.component';
import { DnValidationModule } from '../../dn-validation/src/package.module';

@NgModule({
    imports: [ CommonModule, MatIconModule, MatInputModule, DnValidationModule],
    declarations: [ DnFormTextComponent ],
    exports: [ DnFormTextComponent ]
})

export class DnFormTextModule { }