import { Component, Input, Output, EventEmitter } from "@angular/core";
import { ISelectThemeConfig } from './dn-theme-select.model';

@Component ({
    selector: 'dn-theme-select',
    templateUrl: './dn-theme-select.component.html',
    styleUrls: ['./dn-theme-select.component.scss']
})

export class DnThemeSelectComponent {
    @Input() config:ISelectThemeConfig;
    @Input() dataModel:Object;
    @Output() onChangeTheme: EventEmitter<any> = new EventEmitter();
    @Output() onBlur: EventEmitter<any> = new EventEmitter();

    constructor(){
    }

    ngOnInit(){
        if(typeof this.config.api == 'undefined'){
            this.config.api = {};
        }
    }

    selectionChangeCall(event){
        this.onChangeTheme.emit(event);
    }

    blurCall(){
    }
    
}