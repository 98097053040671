import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar, MatSnackBarConfig } from '@angular/material';


@Component({
    selector: 'dn-snackbar',
    templateUrl: './dn-snackbar.component.html',
    styleUrls: ['./dn-snackbar.component.scss']
})

export class DnSnackbBarComponent implements OnInit {

    constructor(public snackBar: MatSnackBar) {}

    ngOnInit() {
    }
    openSnackbar(msg: string, actionLabel?: string): MatSnackBarRef<SimpleSnackBar> {
        let config = new MatSnackBarConfig();
        config.horizontalPosition = 'left'
        config.duration = 3000
        return this.snackBar.open(msg, actionLabel == null ? undefined : actionLabel, config)
    }
}