import { NgModule } from "@angular/core";
import { DnSearchDrawerComponent } from './dn-search-drawer.component';
import { MatIconModule } from '@angular/material';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [ CommonModule, MatIconModule ],
    declarations: [ DnSearchDrawerComponent ],
    exports: [ DnSearchDrawerComponent ]
})

export class DnSearchDrawerModule {
    constructor(){
        
    }
}