import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { DnValidationDirective } from './dn-validation.directive';

@NgModule({
    imports: [ CommonModule],
    declarations: [ DnValidationDirective ],
    exports: [ DnValidationDirective ]
})

export class DnValidationModule { }