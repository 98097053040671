import { NgModule } from "@angular/core";
import { DnDrawerPanelComponent } from './dn-drawer-panel.component';
import { MatIconModule } from '@angular/material';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [ CommonModule, MatIconModule ],
    declarations: [ DnDrawerPanelComponent ],
    exports: [ DnDrawerPanelComponent ]
})

export class DnDrawerPanelModule {
    constructor(){
    }
}