import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'dn-dialog-preview',
  templateUrl: './dn-dialog-preview.component.html',
  styleUrls: ['./dn-dialog-preview.component.scss']
})

export class DnDialogPreviewComponent {

  constructor(
    public domSanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<DnDialogPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {


  }

  mediaURL(item) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl('https://view.officeapps.live.com/op/embed.aspx?src=' + item);
  }

  onNoClick(returnValue?): void {
    if (returnValue) {
      this.dialogRef.close(returnValue);
    } else {
      this.dialogRef.close();
    }


  }

  download(link) {
    // Actual Source Link
    var tag = document.createElement('a');
    tag.href = link;
    tag.download = 'download';
    tag.click();
  }



}