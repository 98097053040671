import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material';
import { DnThemeSelectComponent } from './dn-theme-select.component';
import { DnValidationModule } from '../../dn-validation/src/package.module';

@NgModule({
    imports: [ CommonModule, MatSelectModule, DnValidationModule],
    declarations: [ DnThemeSelectComponent ],
    exports: [ DnThemeSelectComponent ]
})

export class DnThemeSelectModule { }