import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GlobalService {
    errorObject = [];
    // Step 3
    validatorObject = [];

    addErrorObject(value) {
        this.errorObject = this.errorObject.filter((item) => (item.fieldKey != value.fieldKey));
        this.errorObject.push(value);
    }
    removeValidObject(value) {
        this.validatorObject = this.validatorObject.filter((item) => (item.fieldKey != value.fieldKey));
    }

    getErrorObject() {
        return this.errorObject;
    }

    clearErrorObject(value) {
        this.errorObject = this.errorObject.filter((item) => (item.fieldKey != value.fieldKey));
    }
// Multiple Component Form validation with maps
    errorObjectMap = new Map();
    // Step 3
    validatorObjectMap = new Map();

    clearValidationObjectMap(){
        this.validatorObjectMap.clear();
    }

    deleteValidationObjectMap(key){
        this.validatorObjectMap.delete(key);
    }

    addErrorObjectMap(key ,value) {
        let errorObject = this.errorObjectMap.get(key);
        errorObject = errorObject.filter((item) => (item.fieldKey != value.fieldKey));
        errorObject.push(value);
    }

    removeValidObjectMap(key ,value) {
         let validatorObject = this.validatorObjectMap.get(key);
        validatorObject = validatorObject && validatorObject.filter((item) => (item.fieldKey != value.fieldKey));
         this.validatorObjectMap.set(key,validatorObject);
    }

    getErrorObjectMap(key) {
        return  this.errorObjectMap.get(key);
    }

    clearErrorObjectMap(key ,value) {
        let errorObject = this.errorObjectMap.get(key);
        errorObject = errorObject.filter((item) => (item.fieldKey != value.fieldKey));
    }
}