import {Pipe, PipeTransform} from "@angular/core";
import {CurrencyPipe} from "@angular/common";


@Pipe({name: 'currency'})
export class CurrencyOverride extends CurrencyPipe implements PipeTransform {
  transform(value: any, currencyCode: string): string {
    const currencyFormat = super.transform(value, currencyCode);
    if(currencyFormat) {
        const firstDigit = currencyFormat.search(/\d/);
        return currencyFormat.substring(0, firstDigit) + ' ' + currencyFormat.substr(firstDigit);
    } else {
        return value
    }
  }
}