import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { DnTableComponent } from './dn-table.component';
import { MatIconModule, MatMenuModule, MatPaginatorModule, MatButtonModule,MatListModule, MatFormFieldModule, MatSelectModule, MatSlideToggleModule, MatTooltipModule, MatInputModule, MatCheckboxModule } from '@angular/material';
import { FormsModule } from '@angular/forms';
import { DnFormSelectModule } from '../../dn-form-select/src/package.module';
import { RouterModule } from '@angular/router';
import { NzPopoverModule } from 'ng-zorro-antd/popover';import { DnFilterSelectModule } from 'src/dn-components/dn-filter-select/src/package.module';
;
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { SearchPipe } from 'src/app/search-panel/pipe/search.pipe';
import { DropdownModule } from "primeng/dropdown";
// import {MultiSelectModule} from 'primeng/primeng'
import {RatingModule} from 'primeng/rating';
import { CurrencyOverride } from "../currency-pipe-override/currency-override.pipe";
@NgModule({
  imports: [CommonModule, MatPaginatorModule, MatButtonModule,
    MatMenuModule, MultiSelectModule, MatTooltipModule,
    MatInputModule,
    FormsModule, TableModule, MatIconModule,
    DnFormSelectModule,
    MatFormFieldModule,
    MatSelectModule,
    RouterModule,
    MatSlideToggleModule,
    NzPopoverModule,
    MatCheckboxModule,
    MatListModule,
    DnFilterSelectModule,
    RatingModule,
    OwlDateTimeModule, OwlNativeDateTimeModule,DropdownModule
  ],
  declarations: [DnTableComponent, SearchPipe, CurrencyOverride],
  exports: [DnTableComponent, CurrencyOverride]
})

export class DnTableModule { }