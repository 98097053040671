import { Component, Input, Output, EventEmitter } from "@angular/core";
import { drawerNotificationConfig } from './dn-notification-drawer.model';

@Component({
    selector: 'dn-notification-drawer',
    templateUrl: './dn-notification-drawer.component.html',
    styleUrls: ['./dn-notification-drawer.component.scss']
})

export class DnNotificationDrawerComponent {

    @Input() drawerNotificationConfig: drawerNotificationConfig;
    @Output() closedDrawer: EventEmitter<any> = new EventEmitter();
    @Output() displayData: EventEmitter<any> = new EventEmitter();

    constructor() {

    }

    ngOnInit() { }

    closeDrawer() {
        this.drawerNotificationConfig.isActive = false;
        this.closedDrawer.emit();
    }
}
