import { Component, Input, Output, EventEmitter, Inject } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
// import { ITextConfig } from './dn-form-text.model';
  
@Component ({
    selector: 'ask-question',
    templateUrl: './ask-question.component.html',
    styleUrls: ['./ask-question.component.scss']
})

export class AskQuestionComponent {
    questionModel = {
      category: "",
      subject: "",
      description: ""
    }
      public questionConfig = {
        categoryField: {
          fieldKey: 'category',
          dataKey: 'name',
          options: [
            {name: 'Cat-1'},
            {name: 'Cat-2'},
            {name: 'Cat-3'}
          ],
          attributes: {
            appearance: 'fill',
            label: 'Category',
            class: 'col-xs-4'
          }
        },
        subjectField: {
          fieldKey: 'subject',
          attributes: {
            appearance: 'fill',
            label: 'Subject',
          }
        },
        descriptionField: {
            fieldKey: 'description',
            attributes: {
                appearance: 'fill',
                label: 'Description',
                minHeight: '70px',
                class: 'text-area'
            }
        }
    }    
    constructor(
        public dialogRef: MatDialogRef<AskQuestionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {}
    
      onNoClick(result): void {
        this.dialogRef.close(result);
      }

      // openDialog(): any {
      //   const dialogRef = this.dialog.open(DeleteDialogComponent, {
      //     width: '620px',
      //     height: '326px',
      //   });
    
      //   dialogRef.afterClosed().subscribe(result => {
      //     
      //   });
      // }
    
}