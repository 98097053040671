import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material';
import { DnFilterSelectComponent } from './dn-filter-select.component';
import { DnValidationModule } from '../../dn-validation/src/package.module';

@NgModule({
    imports: [ CommonModule, MatSelectModule, DnValidationModule],
    declarations: [ DnFilterSelectComponent ],
    exports: [ DnFilterSelectComponent ]
})

export class DnFilterSelectModule { }