import { NotificationDialogComponent } from 'src/dn-components/dn-notification-dialog/src/notification-dialog.component';
import { MatDialog } from '@angular/material';
import { AUTHENTICATED_USER_AUTHORITIES, AuthenticationService} from 'src/services/authentication.service';
import { Injectable } from '@angular/core';
import { EndPointConfig } from 'src/app/_config/endpoint.config';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {Router} from "@angular/router"
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

@Injectable({
    providedIn: 'root'
})

export class Utility
{
    ACCESS_DENIED_MSG: string;
    SESSION_EXPIRED_MSG: string;
    ERROR_MSG: string;

    ADMIN_URI: string;
    ORGANIZATION_URI: string;
    COMMON_URI: string;
    USER_URI: string;
    MR_URI: string;
    RFP_URI: string;
    RFP_SUPPLIER_URI: string;
    RFP_SUPPLIER_SET_STATUS_BY_NAME_URI: string;
    RFP_UPDATE_TEXT_URI: string;
    RFP_UPDATE_CONTRACT_URI :string;
    RFP_SUPPLIER_PRICE_URI: string;
    STATIC_FILE_DOWNLOAD: string;
    GET_USER_ROLES_BY_ORG_URI: string;
    GET_USERS_BY_ORG_TYPE_NAME_URI: string;
    GET_USERS_BY_ROLE_NAME_URI: string;
    IS_USER_ACTIVE_URI: string;
    SET_USER_STATUS_URI: string;
    CHANGE_PASSWORD_URI: string;
    GET_ORGANIZATIONS_BY_TYPE_NAME_URI: string;
    SET_ORGANIZATION_STATUS_URI: string;
    GET_ORGANIZATION_COMPLETE_URI: string;
    ADD_SUPPLIER_URI: string;
    GET_ORGANIZATION_DIVERSITY_STATUSES_URI: string;
    GET_COUNTRIES_URI: string;
    GET_REGIONS_URI: string;
    GET_CURRENCIES_URI: string;
    GET_ORGANIZATION_MR_CAPABLITIES_URI: string;
    GET_ORGANIZATION_MR_CAPABLITY_TYPES_URI: string;
    GET_ORGANIZATION_RM_CAPABLITIES_URI: string;
    GET_ORGANIZATION_RM_CAPABLITY_TYPES_URI: string;
    PROJECT_SUPPLIER_UPDATE_PRICING_URI: string;

    PROJECT_URI: string;
    PROJECT_SUPPLIER_URI: string;
    PROJECT_SUPPLIER_RATE:string; //dr
    MR_KEYS_URI: string;
    GET_ANALYSIS_COMPLEXITY_URI: string;
    GET_BANNER_POINTS_URI: string;
    GET_ESTIMATED_INCIDENCE_RATE_URI: string;
    GET_GUIDE_COMPLEXITY_URI: string;
    GET_HCP_TYPE_URI: string;
    GET_INTERVIEW_LENGTH_URI: string;
    GET_QUESTIONNAIRE_COMPLEXITY_URI: string;
    GET_QUESTIONNAIRE_LENGTH_FOR_RECRUTING_URI: string;
    GET_QUESTIONNAIRE_LENGTH_MINUTES_URI: string;
    GET_REPORTING_COMPLEXITY_URI: string;
    GET_RESPONDENT_TYPE_URI: string;
    GET_SCREENER_COMPLEXITY_URI: string;
    GET_STUDY_COMPLEXITY_URI: string;
    GET_STUDY_TYPE_URI: string;
    GET_STUDY_TYPE_URI_ORG:string;
    GET_PROJECT_STATUS_URI: string;
    SET_PROJECT_STATUS_URI: string;
    SET_PROJECT_SUPPLIER_STATUS_URI: string;
    MAP_PROJECT_SUPPLIER_URI: string;
    GET_SUPPLIER_PROJECT_URI: string;
    GET_SUPPLIER_USER_PROJECT_URI: string;
    FORGET_PASS_URI: string;
    RESET_PASS_URI: string;

    GET_HELP_URI: string;
    SETTINGS_URI: string;

    RFP_SUPPLIER_COMPARE_URI: string;
    REPORT_URI: string;
    REPORT_MASTER_REPORT_DATA_URI: string;
    REPORT_SPEND_BY_SUPPLIER_URI: string;
    REPORT_SUPPLIER_SPEND_BY_STUDY_TYPE_URI: string;
    REPORT_NUMBER_OF_TOTAL_STUDY_TYPES_URI: string;
    RFP_SOCIAL_LISTENING_PLATFORM_ACCESS_URI: string;
    RFP_SOCIAL_LISTENING_PLATFORM_ACCESS_OPTIONS_URI: string;
    RFP_SOCIAL_LISTENING_CAPABLITY_URI: string;
    GET_SUPPLIER_PRICING_DROPDOWN_URI: string;
    GET_SUPPLIER_PRICING_DETAILS_URI: string;
    GET_SUPPLIER_PRICING_DETAILS_LIST_URI: string;
    GET_BASE_SUPPLIER_PRICING_DETAILS_URI :string;
    CURRENCY_EXCHANGE :string;
    FAQ :string;
    RFP_COPY_MSA_DETAILS_URI:string;

    constructor(public dialog: MatDialog,
        private authService: AuthenticationService,
        private httpClient: HttpClient,
        private router: Router)
    {
        let endPointConfig = new EndPointConfig();
        this.ACCESS_DENIED_MSG = "You are not allowed to execute this feature";
        this.SESSION_EXPIRED_MSG = 'Your session has expired. Please login again';
        this.ERROR_MSG = 'Unknown exception occured';
        
        this.STATIC_FILE_DOWNLOAD = endPointConfig.baseUrl + "/utility/pmr/static/download";

        this.ADMIN_URI = endPointConfig.baseUrl + "/admin";
        this.ORGANIZATION_URI = this.ADMIN_URI + "/organization";
        this.COMMON_URI = endPointConfig.baseUrl + "/utility";
        this.USER_URI = this.ADMIN_URI + "/user";
        this.MR_URI = endPointConfig.baseUrl + "/mr";
        this.RFP_URI = endPointConfig.baseUrl + "/rfp";
        this.REPORT_URI = endPointConfig.baseUrl + "/report";

        this.RFP_SUPPLIER_URI = this.RFP_URI + "/{organizationId}";
        // this.RFP_SUPPLIER_SET_STATUS_BY_NAME_URI = this.RFP_URI + "/{userId}/{msaId}/status/{statusText}";
        this.RFP_SUPPLIER_SET_STATUS_BY_NAME_URI = this.RFP_URI + "/{userId}/{msaId}/updateStatus/{statusText}";
        
        this.RFP_UPDATE_TEXT_URI = this.RFP_URI + "/update/text";
        this.RFP_UPDATE_CONTRACT_URI = this.RFP_URI + "/update/contractnumber";
        this.RFP_SUPPLIER_PRICE_URI = this.RFP_SUPPLIER_URI + "/baseline-price";
        this.RFP_SUPPLIER_COMPARE_URI = this.RFP_SUPPLIER_PRICE_URI + "/compare";
    
        this.GET_USER_ROLES_BY_ORG_URI = this.USER_URI + "/{name}/roles";
        this.GET_USERS_BY_ORG_TYPE_NAME_URI = this.ORGANIZATION_URI + "/type-name/{name}/users";
        this.GET_USERS_BY_ROLE_NAME_URI = this.ORGANIZATION_URI + "/role-name/{name}/users";
        this.IS_USER_ACTIVE_URI = this.COMMON_URI + "/user/{id}/active";
        this.SET_USER_STATUS_URI = this.USER_URI + "/{userIds}/status/{statusId}";
        this.CHANGE_PASSWORD_URI = this.USER_URI + "/change-password";
        this.GET_ORGANIZATIONS_BY_TYPE_NAME_URI = this.ORGANIZATION_URI + "/type-name/{name}";
        this.SET_ORGANIZATION_STATUS_URI = this.ORGANIZATION_URI + "/{organizationIds}/status/{statusId}";
        this.GET_ORGANIZATION_COMPLETE_URI = this.ORGANIZATION_URI + "/complete/{id}";
        this.ADD_SUPPLIER_URI = this.ORGANIZATION_URI + "/complete";
        this.GET_ORGANIZATION_DIVERSITY_STATUSES_URI = this.ORGANIZATION_URI + "/statuses/diversity";
        this.GET_COUNTRIES_URI = this.COMMON_URI + "/countries";
        this.GET_REGIONS_URI = this.COMMON_URI + "/regions";
        this.GET_CURRENCIES_URI = this.COMMON_URI + "/currencies";
        this.GET_ORGANIZATION_MR_CAPABLITIES_URI = this.ORGANIZATION_URI + "/capability/research/market";
        this.GET_ORGANIZATION_MR_CAPABLITY_TYPES_URI = this.ORGANIZATION_URI + "/capability/research/market/types";
        this.GET_ORGANIZATION_RM_CAPABLITIES_URI = this.ORGANIZATION_URI + "/capability/methodology/research";
        this.GET_ORGANIZATION_RM_CAPABLITY_TYPES_URI = this.ORGANIZATION_URI + "/capability/methodology/research/types";

        this.PROJECT_URI = this.MR_URI + "/project";
        this.PROJECT_SUPPLIER_URI = this.MR_URI + "/supplier-project/{organizationId}";
        this.PROJECT_SUPPLIER_RATE= this.MR_URI+"/project-supplier-rate" //dikshit
        this.MR_KEYS_URI = this.MR_URI + "/keys";
        this.GET_ANALYSIS_COMPLEXITY_URI = this.MR_KEYS_URI + "/analysis-complexity";
        this.GET_BANNER_POINTS_URI = this.MR_KEYS_URI + "/banner-points";
        this.GET_ESTIMATED_INCIDENCE_RATE_URI = this.MR_KEYS_URI + "/estimated-incidence-rate";
        this.GET_GUIDE_COMPLEXITY_URI = this.MR_KEYS_URI + "/guide-complexity";
        this.GET_HCP_TYPE_URI = this.MR_KEYS_URI + "/hcp-type";
        this.GET_INTERVIEW_LENGTH_URI = this.MR_KEYS_URI + "/interview-length";
        this.GET_QUESTIONNAIRE_COMPLEXITY_URI = this.MR_KEYS_URI + "/questionnaire-complexity";
        this.GET_QUESTIONNAIRE_LENGTH_FOR_RECRUTING_URI = this.MR_KEYS_URI + "/questionnaire-length-for-recruting";
        this.GET_QUESTIONNAIRE_LENGTH_MINUTES_URI = this.MR_KEYS_URI + "/questionnaire-length-minutes";
        this.GET_REPORTING_COMPLEXITY_URI = this.MR_KEYS_URI + "/reporting-complexity";
        this.GET_RESPONDENT_TYPE_URI = this.MR_KEYS_URI + "/respondent-type";
        this.GET_SCREENER_COMPLEXITY_URI = this.MR_KEYS_URI + "/screener-complexity";
        this.GET_STUDY_COMPLEXITY_URI = this.MR_KEYS_URI + "/study-complexity";
        this.GET_STUDY_TYPE_URI = this.MR_KEYS_URI + "/study-type";
        this.GET_STUDY_TYPE_URI_ORG = this.MR_KEYS_URI+ "/study-type/{organizationId}";
        this.GET_PROJECT_STATUS_URI = this.MR_KEYS_URI + "/project-status";
        this.SET_PROJECT_STATUS_URI = this.PROJECT_URI + "/{projectId}/status/{statusName}";
        this.SET_PROJECT_SUPPLIER_STATUS_URI = this.PROJECT_URI + "/{projectSupplierIds}/supplier-status/{statusName}";
        this.GET_SUPPLIER_PRICING_DROPDOWN_URI = this.RFP_URI + "/getSuppliarPricingDD";
        this.GET_SUPPLIER_PRICING_DETAILS_URI =  this.RFP_URI + "/getSupplierPricingDetails/{msaVersionId}/{complexity}";
        this.GET_BASE_SUPPLIER_PRICING_DETAILS_URI =  this.RFP_URI + "/getBaseSupplierDetails/{msaVersionId}/{complexity}";
        this.GET_SUPPLIER_PRICING_DETAILS_LIST_URI = this.RFP_URI + "/getSupplierPricingDetailsList";

        this.MAP_PROJECT_SUPPLIER_URI = this.PROJECT_URI + "/{projectId}/map/{organizationId}";
        this.GET_SUPPLIER_PROJECT_URI = this.PROJECT_URI + "/organization/{organizationId}";
        this.GET_SUPPLIER_USER_PROJECT_URI = this.PROJECT_URI + "/user/{userId}";
        
        this.FORGET_PASS_URI = this.ADMIN_URI + "/login/forget-password/send-password-reset-email/{emailId}";
        this.RESET_PASS_URI = endPointConfig.baseUrl + "/login/reset-password";

        this.PROJECT_SUPPLIER_UPDATE_PRICING_URI = this.MR_URI + "/supplier-project/update-pricing";
        this.GET_HELP_URI = this.COMMON_URI + "/help";
        this.SETTINGS_URI = this.COMMON_URI + "/settings";

        this.REPORT_MASTER_REPORT_DATA_URI = this.REPORT_URI + "/master/download";
        this.REPORT_SPEND_BY_SUPPLIER_URI = this.REPORT_URI + "/spend-by-supplier/{level}";
        this.REPORT_SUPPLIER_SPEND_BY_STUDY_TYPE_URI = this.REPORT_URI + "/supplier-spend-by-study-type/{level}";
        this.REPORT_NUMBER_OF_TOTAL_STUDY_TYPES_URI = this.REPORT_URI + "/number-of-total-study-types/{level}";

        this.RFP_SOCIAL_LISTENING_PLATFORM_ACCESS_URI = this.RFP_URI + "/social-listening/platform-access";
        this.RFP_SOCIAL_LISTENING_PLATFORM_ACCESS_OPTIONS_URI = this.RFP_URI + "/social-listening/platform-access-options";
        this.RFP_SOCIAL_LISTENING_CAPABLITY_URI = this.RFP_URI + "/social-listening/platform-access-capablities";
        this.CURRENCY_EXCHANGE = "/currency/exchange/";
        this.FAQ = "/faq/";
        this.RFP_COPY_MSA_DETAILS_URI=this.RFP_URI+"/{organizationId}/{msaId}/{userId}";
        }

    getCountries(): Observable<any> {
        return this.httpClient.get(this.GET_COUNTRIES_URI);
    }

    getRegions(): Observable<any> {
        return this.httpClient.get(this.GET_REGIONS_URI);
    }

    canDisplayDashboard(){
        return localStorage.getItem("roleType")== "JnJ Admin" 
        || localStorage.getItem("roleType")== "JnJ Super User" 
        || localStorage.getItem("roleType")== "JnJ Contact";
    }
    allowChangePassword(){
        return !(localStorage.getItem("roleType")== "JnJ Admin" 
        || localStorage.getItem("roleType")== "JnJ Super User" 
        || localStorage.getItem("roleType")== "JnJ Contact");
    }
    
    checkModuleAccess(modules: string[]): boolean
    {
        let authorities = localStorage.getItem(AUTHENTICATED_USER_AUTHORITIES).split(',');
        let found = false;

        modules.forEach(module => {
            if (authorities.includes(module))
                found = true;
        });

        return found;
    }

    public imageToDb(img){
        // return img.replace('data:image/jpeg;base64,','');
        return img.replace(/^[^,]+, */, '');
    }

    public imageFromDb(img){
        return 'data:image/jpeg;base64,' + img;
    }

    public handleError(error: HttpErrorResponse, msg?: string) {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error.message}`);
    
          this.showNotification(msg == null ? "" : msg, 'error');
        }
        // return an observable with a user-facing error message
        return throwError(
          'Something bad happened; please try again later.')
      };

    sessionExpiredNotification(){
        this.showNotification(this.SESSION_EXPIRED_MSG,'error');
        this.router.navigate(['/']);
        }

    showNotification(msg, msgType): any
    {
        let window = {
                        width: '620px',
                        height: '326px',
                        autoFocus: false,
                        data:
                        {
                            type: msgType, // error, success, information, delete, warning 
                            message: msg,
                            actions:
                            [
                                // Types of buttons
                                //  { name: "try again", class: "secondary-btn" },
                                //   { name: "continue", class: "secondary-btn" },
                                //   { name: "done", class: "secondary-btn" },
                                //   { name: "yes", class: "secondary-btn" },
                                { name: "close", class: "secondary-btn" },
                                //  { name: "cancel", class: "secondary-btn" },
                                //   { name: "no", class: "tertiary-btn" }
                            ]
                        }
                    }

        const dialogRef = this.dialog.open(NotificationDialogComponent,window);

        dialogRef.afterClosed().subscribe
        (
            result =>
            {
                switch(result)
                {
                    case 'try again':
                        //logic for try again
                        break;
                    case 'continue':
                        //logic for continue
                        break;
                    case 'done':
                        //logic for done
                        break;
                    case 'yes':
                        //logic for yes
                        break;
                    case 'close':
                        //logic for close
                        break;
                    case 'cancel':
                        //logic for cancel
                        break;
                    case 'close':
                        //logic for close
                        break;
                    default:
                        break;
                }
            }
        );
    }
    jsonToExcelDownload(json: any[], excelFileName: string): void {​​​​​
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {​​​​​ Sheets: {​​​​​ 'data': worksheet }​​​​​, SheetNames: ['data'] }​​​​​;
    const excelBuffer: any = XLSX.write(workbook, {​​​​​ bookType:'xlsx', type:'array' }​​​​​);
    this.saveAsExcelFileNew(excelBuffer, excelFileName);
      }​​​​​

    private saveAsExcelFileNew(buffer: any, fileName: string): void {​​​​​
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {​​​​​
    type:EXCEL_TYPE
        }​​​​​);
    FileSaver.saveAs(data, fileName + '_report_' + new Date().getTime() + EXCEL_EXTENSION);
      }​​​​​
    
}
