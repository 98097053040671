import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { MatIconModule, MatButtonModule, MatProgressSpinnerModule, MatProgressBarModule } from '@angular/material';
import { DnDragDropUploadComponent } from './dn-drag-drop-upload.component';
import { DragDirective } from './drag-drop.directive';

@NgModule({
    imports: [ CommonModule, MatIconModule, MatProgressBarModule, MatButtonModule, MatProgressSpinnerModule ],
    declarations: [ DnDragDropUploadComponent, DragDirective ],
    exports: [ DnDragDropUploadComponent ]
})

export class DnDragDropUploadModule {
    constructor(){ }
}