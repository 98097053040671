import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { InfoDialogComponent } from 'src/dn-components/dn-info-notification/src/info-dialog.component';
import { MatDialog } from '@angular/material';
import { GlobalService } from 'src/services/global.service';
@Component({
    selector: 'forget-pass',
    templateUrl: './forget-pass.component.html',
    styleUrls: ['./forget-pass.component.scss']
})

export class ForgetPassComponent implements OnInit, OnDestroy {

    @Input() forgetPassModel = {
        emailAddressVerified: '',
    }

    public forgetPasswordConfig = {
        emailAddressVerified: {
            fieldKey: 'emailAddressVerified',
            attributes: {
                type: 'text',
                label: 'Email Address',
                appearance: 'fill',
                pattern: 'emailOnly',
                isMandatory: true
            }
        }
    }

    @Output() onButtonClicked: EventEmitter<any> = new EventEmitter();
    @Output() changePasswordView = new EventEmitter<number>();
    viewValue = 0;

    constructor(
        public dialog: MatDialog,
        public globalService: GlobalService,
    ) { }
    btnClicked(evt, btnType) {
        evt['btnType'] = btnType;
        this.onButtonClicked.emit(evt);
    }
    sendMessageToParent(message: number) {
        this.changePasswordView.emit(message)
        this.apiCallBackFunction();
    }

    ngOnInit() {
        
        

        // Step 1
        let mandatoryFields = Object.values(this.forgetPasswordConfig).filter(
            (key) =>
                (key.attributes.isMandatory)
        );
        this.globalService.validatorObject = mandatoryFields;
    }

    ngOnDestroy() {
        this.globalService.validatorObject = [];
        this.globalService.errorObject = [];
    }
    apiCallBackFunction(msg = 'Call the Api Service'): any {
        const dialogRef = this.dialog.open(InfoDialogComponent, {
            width: '620px',
            height: '326px',
            autoFocus: false,
            data: msg
        });

        dialogRef.afterClosed().subscribe(result => {
            // this.router.navigate(['/dashboard']);
        });
    }
}