import { Component, Input, Output, EventEmitter, Inject } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
// import { ITextConfig } from './dn-form-text.model';
  
@Component ({
    selector: 'error-dialog',
    templateUrl: './error-dialog.component.html',
    styleUrls: ['./dn-error-notification.component.scss']
})

export class ErrorDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<ErrorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {}
    
      onNoClick(): void {
        this.dialogRef.close();
      }

      // openDialog(): any {
      //   const dialogRef = this.dialog.open(DeleteDialogComponent, {
      //     width: '620px',
      //     height: '326px',
      //   });
    
      //   dialogRef.afterClosed().subscribe(result => {
      //     
      //   });
      // }
    
}