import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
@Injectable({
    providedIn: 'root'
})
export class HttpInterceptorJwtAuthService implements HttpInterceptor {

    constructor(private authenticationService: AuthenticationService, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        let basicAuthHeaderString = this.authenticationService.getAuthenticatedToken();
        let username = this.authenticationService.getAuthenticatedUser()

        if (basicAuthHeaderString && username) {
            request = request.clone({
                setHeaders: {
                    Authorization: basicAuthHeaderString
                }
            })
        }

        return next.handle(request).pipe(tap(() => { },
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status == 401 || err.status == 403) {
                        localStorage.clear();
                        sessionStorage.clear();
                        this.router.navigate(['login']);
                    }
                    return;
                }
            }));
    }
}
