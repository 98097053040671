import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material';
import { DnFormSelectComponent } from './dn-form-select.component';
import { DnValidationModule } from '../../dn-validation/src/package.module';

@NgModule({
    imports: [ CommonModule, MatSelectModule, DnValidationModule],
    declarations: [ DnFormSelectComponent ],
    exports: [ DnFormSelectComponent ]
})

export class DnFormSelectModule { }