import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    baseUrl: string;
    constructor(private httpClient: HttpClient) {
        // this.baseUrl = new EndPointConfig().baseUrl;
        this.baseUrl = './../assets/json/08_Notification/';
    }


    //GET 
    getAllNotification(): Observable<any[]> {
        return this.httpClient.get<any[]>(this.baseUrl + '/1-GetAllNotifications.json');
    }

    getAllAnnouncements(): Observable<any[]> {
        return this.httpClient.get<any[]>(this.baseUrl + '2-GetAllAnnouncements.Json');
    }
    getNotificationTypes(): Observable<any[]> {
        return this.httpClient.get<any[]>(this.baseUrl + '3-NotificationsTypes.json');
    }
​
    getNotificationList(typeId): Observable<any[]> {
        return this.httpClient.get<any[]>(this.baseUrl + '4-NotificationLists.json');
    }

    getNotificationTemplate(id?): Observable<any> {
        return this.httpClient.get<any>(this.baseUrl + '5-EditNotifications.json');
    }
    getVariables(): Observable<any[]> {
        return this.httpClient.get<any[]>(this.baseUrl + '6-NotificationsVariables.json');
    }

    //POST
    public setStatus(payLoad): Observable<any> {
        return this.httpClient.post<any>(`${this.baseUrl}StatusChange.json`, payLoad);
    }



}

