import { Component, Inject, ViewChild, OnInit, OnDestroy, Output, EventEmitter } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { DnWidgetTableDrilldownComponent } from 'src/dn-components/dn-widget-table-drilldown/src/dn-widget-table-drilldown.component';
import { DnTableComponent } from 'src/dn-components/dn-table/src/dn-table.component';
import moment from 'moment';
import { UIChart } from 'primeng/chart';
import _ from 'lodash';

@Component({
  selector: "dn-dialog-fullscreen",
  templateUrl: "./dn-dialog-fullscreen.component.html",
  styleUrls: ["./dn-dialog-fullscreen.component.scss"],
})
export class DnDialogFullScreenComponent implements OnInit, OnDestroy {
  @ViewChild('widget', {static: false}) widget: DnWidgetTableDrilldownComponent;
  @ViewChild('table', {static: false}) table: DnTableComponent;
  @ViewChild('pchart', { static: false }) pchart: UIChart;
  @Output() onLinkClick: EventEmitter<any> = new EventEmitter();
  @Output() onRefresh: EventEmitter<any> = new EventEmitter();
  @Output() changeChart: EventEmitter<any> = new EventEmitter();
  selectedDurationFilter = null;
  selectedDuration = null;
  filteredBy = null;
  constructor(
    public dialogRef: MatDialogRef<DnDialogFullScreenComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(returnValue?): void {
    if (returnValue) {
      this.dialogRef.close(returnValue);
    } else {
      this.dialogRef.close();
    }
  }
  ngOnInit() {
    if(this.data.config.tableConfig) {
      this.data.config.tableConfig.hidePagination = false;
      this.data.config.tableConfig.tableHeight = 'calc(92vh - 200px)'
    }
  }

  ngOnDestroy() {
    if(this.data.config.tableConfig) {
      this.data.config.tableConfig.hidePagination = true;
    }
  }
  
  convertEpoch(date) {
    return new Date(date * 1000).getTime();
  }

  googleChartSelect(event) {
    if(event.selectedRowValues[1] == "All Files") {
      this.data.config.filter = event.selectedRowValues[0]
      this.changeChart.emit("pie")
      
    }
  }

  filterByDuration(pattern: any,field: any) {
    if(this.data.type == 'table') {
      this.selectedDuration = pattern;
  
      // const today = moment().startOf('day');
      const variance = moment().startOf('day').subtract(pattern.metric, pattern.type).toString();
      // const checkDate = new Date(variance).getTime() / 1000;
      const checkDate = new Date(variance).getTime();
      this.table.dataTable.filter(checkDate, field, "gt");
      
    } else {
    this.data.config.settings.selectedDurationFilter = field //take index of filter from field param
      // this.selectedDurationFilter = field; 
    const records = this.data.config.settings.dataForFilter.records;
    const filterField = this.data.config.settings.dataForFilter.filterField;
    const valueField = this.data.config.settings.dataForFilter.valueField;
    const isLengthBased = this.data.config.settings.dataForFilter.isLengthBased;

    if(pattern.type == 'max') {
      let date = _.groupBy(records, function (element: any) {
        const date = moment(new Date(element[filterField] * 1000)).format(
          "MMM-YYYY"
        );
        return date;
      });
      this.data.config.dataConfig.labels = [];
      this.data.config.dataConfig.datasets[0].data = [];

      Object.keys(date).map((key) => {
        //set labels
        this.data.config.dataConfig.labels.push(key);
        let sumTotal: number = 0;
        date[key].map((item: any) => {
          sumTotal += item[valueField];
        });
        if(isLengthBased) {
          this.data.config.dataConfig.datasets[0].data.push(
            date[key].length
          );
        } else {
          this.data.config.dataConfig.datasets[0].data.push(
            sumTotal
          );
        }
        
      });
    } else {
      let points = []
      const today = moment();
      const variance = moment().subtract(pattern.metric, pattern.type);
      const stringArrayDates = this.enumerateMonthsBetweenDates(variance,today)
      stringArrayDates.forEach(date => {
        let pointObject = {
            day: date,
            values: []
        }
        let tempArray = []
        pointObject.values = records.filter(function(item: any) {
            let itemDate = new Date(0)
            itemDate.setUTCSeconds(item[filterField])
            tempArray.push(itemDate)
            let dateString = moment(itemDate).format('MMM-YYYY');
            // 
            return dateString == date
        })
        points.push(pointObject)
    })
      this.data.config.dataConfig.labels = [];
      this.data.config.dataConfig.datasets[0].data = [];
      points.forEach(point => {   
        this.data.config.dataConfig.labels.push(point.day)
        if(isLengthBased) {
          this.data.config.dataConfig.datasets[0].data.push(point.values.length)
        } else {
          this.data.config.dataConfig.datasets[0].data.push(this.sumofArray(point.values,valueField))
        }
      })
    }
    this.pchart.refresh()
    }
  }
  
  filterTable(field, selectEvent) {
    this.filteredBy = selectEvent['data']
    this.table.dataTable.filter(this.filteredBy,field,'equals')
    this.data.config.settings.showTable = true
  }
  tableLinkClick(e) {
    if (this.table.dataTable.hasFilter()) {
      e['filteredData'] = this.table.dataTable.filteredValue;
    }
    else {
      e['filteredData'] = this.data.config.tableConfig.rowData;
    }
    this.onLinkClick.emit(e)
  }

  resetTable() {
    this.filteredBy = null
    this.data.config.settings.showTable = false;
    this.table.dataTable.reset();
  }


  
  refreshChart(event?) {
    if(event) {
      this.onRefresh.emit(event.value)
    } else {
      this.onRefresh.emit()
    }
  }
  selectData(e) {
    // alert(e)
    
    
    e['eventType'] = e.originalEvent.type;
    e['data'] = e.element._model.label;
    this.data.onClick.emit(e)
  }
  enumerateMonthsBetweenDates = function(startDate, endDate) {
    var dates = [];
    
        var currDate = moment(startDate).startOf('day');
        var lastDate = moment(endDate).startOf('day');
        dates.push(currDate.clone().format('MMM-YYYY'));
        while(currDate.add(1, 'month').diff(lastDate) <= 0) {
            dates.push(currDate.clone().format('MMM-YYYY'));
        }
        return dates;
  };
  sumofArray(array, objectKey) {
    return array.reduce(function(a, b){
      return a + b[objectKey];
    }, 0)
  }
}
