import { Component, Input, Output, EventEmitter } from "@angular/core";
import { IDateConfig } from './dn-form-date.model';
import { FormControl } from '@angular/forms';
import { DatePipe } from "@angular/common";

@Component({
    selector: 'dn-form-date',
    templateUrl: './dn-form-date.component.html',
    styleUrls: ['./dn-form-date.component.scss'],
    providers: [DatePipe]
})

export class DnFormDateComponent {
    public date: FormControl;
    @Input() config: IDateConfig;
    @Input() dataModel: Object;
    @Output() onDateChange: EventEmitter<any> = new EventEmitter();
    @Output() onBlur: EventEmitter<any> = new EventEmitter();


    constructor(private datePipe: DatePipe) { }

    ngOnInit() {
        
        if (this.dataModel[this.config.fieldKey] == null || this.dataModel[this.config.fieldKey] == 0) {
            this.date = new FormControl(new Date(''));
        } else {
            this.date = new FormControl(new Date(this.dataModel[this.config.fieldKey]));
        }

        if (typeof this.config.api == 'undefined') {
            this.config.api = {};
        }
    }

    dateChangeCall(event) {
        if (this.config.attributes.onlyDate) {
            this.dataModel[this.config.fieldKey] = this.datePipe.transform(event.value, 'y-MM-dd');
        } else {
            this.dataModel[this.config.fieldKey] = new Date(event.value).getTime();
        }
        this.onDateChange.emit(event);
    }

    closedCall() {
        this.config.api.refreshValidation();
    }

    blurCall() {
        this.config.api.refreshValidation();
        this.onBlur.emit(event);
    }

}