import { Component, Input, Output, EventEmitter } from "@angular/core";
import { ISelectConfig } from './dn-form-select.model';

@Component({
    selector: 'dn-form-select',
    templateUrl: './dn-form-select.component.html',
    styleUrls: ['./dn-form-select.component.scss']
})

export class DnFormSelectComponent {
    @Input() config: ISelectConfig;
    @Input() dataModel: Object;
    @Output() onSelectionChange: EventEmitter<any> = new EventEmitter();
    @Output() onBlur: EventEmitter<any> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
        if (typeof this.config.api == 'undefined') {
            this.config.api = {};
        }
    }

    selectionChangeCall(event) {
        this.onSelectionChange.emit(event);
    }

    blurCall() {
        setTimeout(() => {
            this.config.api.refreshValidation();
            this.onBlur.emit();
        }, 100)
    }

}