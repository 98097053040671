import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EndPointConfig } from 'src/app/_config/endpoint.config';
import { Observable } from 'rxjs';
import { Utility } from 'src/modules/common/Utility';

@Injectable({
    providedIn: 'root'
})
export class ManageUserService {

    baseUrl: string;

    constructor(private httpClient: HttpClient, private utility: Utility) {
        // this.baseUrl = new EndPointConfig().baseUrl;
        this.baseUrl = '../assets/json/pmr/';
    }
    //GET 
    public getAllUser(): Observable<any[]> {
        return this.httpClient.get<any[]>(this.baseUrl + '65.user_jnj.json');
    }

    public getUserProfile(userId: string): Observable<any> {
        // return this.httpClient.get<any>(this.baseUrl + 'profile_user.json');
        return this.httpClient.get<any>(`${this.utility.USER_URI}/${userId}`);
    }

    public editUserProfile(data): Observable<any> {
        return this.httpClient.post(`${this.utility.USER_URI}`, data);
    }

    public getAllUserActivity(): Observable<any[]> {
        return this.httpClient.get<any[]>(this.baseUrl + 'CORPOATE_DASHBOARD_TEMP.json');
    }

    public getLocations(): Observable<any[]> {
        return this.httpClient.get<any[]>('../assets/json/countries.json');
    }
    public getLabels(): Observable<any> {
        return this.httpClient.get<any>(this.baseUrl + '/1-WhiteLabel1.json');
    }

    //GET 
    public birthdayGet(): Observable<any[]> {
        return this.httpClient.get<any[]>(this.baseUrl + '/PPT2_BIRTDAY_GET.json');
    }
    //GET 
    public aniversaryGet(): Observable<any[]> {
        return this.httpClient.get<any[]>(this.baseUrl + '/PPT2_ANNIVERSARY_GET.json');
    }
    public sendRegards(birthdayRegard) {
        return this.httpClient.post(`${this.baseUrl}/sendRegards/`, birthdayRegard);
    }

    public getRolesByOrganizationTypeName(name): Observable<any[]> {
        return this.httpClient.get<any[]>(this.utility.GET_USER_ROLES_BY_ORG_URI.replace('{name}',name));
    }

    public getUsersByOrganizationTypeName(name): Observable<any[]> {
        return this.httpClient.get<any[]>(this.utility.GET_USERS_BY_ORG_TYPE_NAME_URI.replace('{name}',name));
    }

    public getUsersByRoleName(name): Observable<any[]> {
        return this.httpClient.get<any[]>(this.utility.GET_USERS_BY_ROLE_NAME_URI.replace('{name}',name));
    }

    public updateStatus(status,ids): Observable<any>{
        return this.httpClient.patch(this.utility.SET_USER_STATUS_URI.replace('{userIds}',ids).replace('{statusId}',status), {responseType: 'text'});
    }

    public changePassword(data): Observable<any>{
        return this.httpClient.patch(this.utility.CHANGE_PASSWORD_URI, data, {responseType: 'json'});
    }
}