import { Component, Input, Output, EventEmitter } from "@angular/core";
import { ITextareaConfig } from './dn-form-textarea.model';

@Component ({
    selector: 'dn-form-textarea',
    templateUrl: './dn-form-textarea.component.html',
    styleUrls: ['./dn-form-textarea.component.scss']
})

export class DnFormTextareaComponent {
    @Input() config:ITextareaConfig;
    @Input() dataModel:Object;
    @Output() onKeyup: EventEmitter<any> = new EventEmitter();
    @Output() onBlur: EventEmitter<any> = new EventEmitter();
    

    constructor(){ }

    ngOnInit(){
        if(typeof this.config.api == 'undefined'){
            this.config.api = {};
        }
    }

    modelChangeCall(event){
        this.dataModel[this.config.fieldKey] = event.target.value;
    }

    keyupCall(event){
        this.onKeyup.emit(event);
    }

    blurCall(){
        this.config.api.refreshValidation();
        this.onBlur.emit(event);
    }
    
}