export class AclData {
  public aclModule = [
    {
      "moduleName": "search",
      "accessLevel": true,
      "level": 1
    },
    {
      "moduleName": "manage-role",
      "accessLevel": true,
      "level": 1
    },
    {
      "moduleName": "admin",
      "accessLevel": true,
      "level": 1
    },
    {
      "moduleName": "lockbox",
      "accessLevel": true,
      "level": 1
    },
    {
      "moduleName": "datalake",
      "accessLevel": true,
      "level": 1
    },
    {
      "moduleName": "tools",
      "accessLevel": true,
      "level": 1
    },
    {
      "moduleName": "announcement",
      "accessLevel": true,
      "level": 1
    },
    {
      "moduleName": "notifications",
      "accessLevel": true,
      "level": 1
    },
    {
      "moduleName": "help",
      "accessLevel": true,
      "level": 1
    },
    {
      "moduleName": "settings",
      "accessLevel": true,
      "level": 1
    },
    {
      "moduleName": "project",
      "accessLevel": true,
      "level": 1
    },
    {
      "moduleName": "admin.manage-org",
      "accessLevel": true,
      "level": 2
    },
    {
      "moduleName": "admin.manage-user",
      "accessLevel": true,
      "level": 2
    },
    {
      "moduleName": "admin.manage-notifications",
      "accessLevel": true,
      "level": 2
    },
    {
      "moduleName": "admin.manage-audit-trail",
      "accessLevel": true,
      "level": 2
    },
    {
      "moduleName": "admin.manage-business-unit",
      "accessLevel": true,
      "level": 2
    },
    {
      "moduleName": "admin.manage-department",
      "accessLevel": true,
      "level": 2
    },
    {
      "moduleName": "admin.manage-subscription",
      "accessLevel": true,
      "level": 2
    },
    {
      "moduleName": "admin.manage-masters",
      "accessLevel": true,
      "level": 2
    },
    {
      "moduleName": "lockbox.my-lockbox",
      "accessLevel": true,
      "level": 2
    },
    {
      "moduleName": "lockbox.client-lockbox",
      "accessLevel": true,
      "level": 2
    },
    {
      "moduleName": "tools.network-track-master",
      "accessLevel": true,
      "level": 2
    },
    {
      "moduleName": "tools.network-track-client",
      "accessLevel": false,
      "level": 2
    },
    {
      "moduleName": "tools.network-track-advisor",
      "accessLevel": true,
      "level": 2
    },
    {
      "moduleName": "tools.decision-matrix",
      "accessLevel": true,
      "level": 2
    },
    {
      "moduleName": "datalake.ingestor",
      "accessLevel": true,
      "level": 2
    },
    {
      "moduleName": "datalake.searchbird",
      "accessLevel": true,
      "level": 2
    },
    {
      "moduleName": "tools.decision-matrix-admin",
      "accessLevel": true,
      "level": 2
    },
    {
      "moduleName": "notifications.announcement",
      "accessLevel": true,
      "level": 2
    },
    {
      "moduleName": "lockbox.my-lockbox.repository",
      "accessLevel": true,
      "level": 3
    },
    {
      "moduleName": "datalake.ingestor.datalake-workbench",
      "accessLevel": true,
      "level": 3
    },
    {
      "moduleName": "admin.manage-user.company-dd",
      "accessLevel": true,
      "level": 3
    },
    {
      "moduleName": "admin.manage-user.users-tab",
      "accessLevel": true,
      "level": 3
    },
    {
      "moduleName": "admin.manage-user.users-group-tab",
      "accessLevel": true,
      "level": 3
    },
    {
      "moduleName": "admin.manage-user.users-add",
      "accessLevel": true,
      "level": 3
    },
    {
      "moduleName": "admin.manage-user.users-group-add",
      "accessLevel": true,
      "level": 3
    },
    {
      "moduleName": "user-profile.account.user-profile",
      "accessLevel": true,
      "level": 3
    },
    {
      "moduleName": "user-profile.account.organization-profile",
      "accessLevel": true,
      "level": 3
    },
    {
      "moduleName": "user-profile.account.password",
      "accessLevel": true,
      "level": 3
    },
    {
      "moduleName": "user-profile.account.settings",
      "accessLevel": true,
      "level": 3
    },
    {
      "moduleName": "admin.manage-org.client-tab",
      "accessLevel": true,
      "level": 3
    },
    {
      "moduleName": "lockbox.my-lockbox.repository.view",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "lockbox.my-lockbox.repository.add",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "lockbox.my-lockbox.repository.delete",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "lockbox.my-lockbox.repository.download",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "lockbox.my-lockbox.repository.rename",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "lockbox.my-lockbox.repository.tag",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "lockbox.my-lockbox.repository.print",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "lockbox.my-lockbox.share",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "lockbox.my-lockbox.upload",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "lockbox.my-lockbox.repository.bulk-delete",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "lockbox.my-lockbox.more-options.tags",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "lockbox.my-lockbox.more-options.export",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "admin.manage-user.users-add.bulk-reg",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "admin.manage-user.users-add.quick-reg",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "admin.manage-user.users-tab.manage-map",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "admin.manage-user.users-group-tab.add-org-details",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "admin.manage-user.users-group-tab.add-privacy-details",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "admin.manage-user.users-add.bulk-reg",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "admin.manage-user.users-add.quick-reg",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "admin.manage-user.users-tab.manage-map",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "admin.manage-user.users-group-tab.add-org-details",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "admin.manage-user.users-group-tab.add-privacy-details",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "admin.manage-user.users-group-tab.delete",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "admin.manage-user.users-group-tab.edit",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "admin.manage-user.users-group-tab.deactivate",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "admin.manage-user.users-group-tab.activate",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "admin.manage-user.users-tab.delete",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "admin.manage-user.users-tab.edit",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "admin.manage-user.users-tab.deactivate",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "admin.manage-user.users-tab.activate",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "admin.manage-org.client-tab.filter",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "lockbox.my-lockbox.repository.bulk-download",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": "lockbox.my-lockbox.repository.add.folder",
      "accessLevel": true,
      "level": 4
    },
    {
      "moduleName": null,
      "accessLevel": false,
      "level": 5
    }
  ];
}