import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule, NoopAnimationsModule } from "@angular/platform-browser/animations";
import { MatSidenavModule } from "@angular/material/sidenav";
import {
  MatIconModule,
  MatButtonModule,
  MatBadgeModule,
  MatTooltipModule,
  MatProgressBarModule,
  MatAutocompleteModule,
  MatInputModule,
  MatToolbarModule,
  MatRadioModule,
  MatTabsModule,
} from "@angular/material";
import { DnDrawerPanelModule } from "../dn-components/dn-drawer-panel/src/package.module";
import { RouterModule } from "@angular/router";
import { DnFormTextModule } from "../dn-components/dn-form-text/src/package.module";
import { DnFormSelectModule } from "../dn-components/dn-form-select/src/package.module";
import { SearchPanelComponent } from "./search-panel/search-panel.component";
import { UserProfileComponent } from "./user-profile/user-profile.component";
import { NavigationComponent } from "./navigation/navigation.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { DnProfileDrawerModule } from "src/dn-components/dn-profile-drawer/src/package.module";
import { ChangePassComponent } from "./user-profile/change-pass/change-pass.component";
import { DnFormDateModule } from "src/dn-components/dn-form-date/src/package.module";
import { ForgetPassComponent } from "./user-profile/forget-pass/forget-pass.component";
import { ForgetPassAuthComponent } from "./user-profile/forget-pass-auth/forget-pass-auth.component";
import { NewPassComponent } from "./user-profile/new-pass/new-pass.component";
import { DeleteDialogComponent } from "src/dn-components/dn-delete-notification/src/delete-dialog.component";
import { ErrorDialogComponent } from "src/dn-components/dn-error-notification/src/error-dialog.component";
import { InfoDialogComponent } from "src/dn-components/dn-info-notification/src/info-dialog.component";
import { SuccessDialogComponent } from "src/dn-components/dn-success-notification/src/success-dialog.component";
import { DnThemeSelectModule } from "src/dn-components/dn-theme-select/src/package.module";
import { DnNotificationDrawerModule } from "src/dn-components/dn-notification-drawer/src/package.module";
import { NotificationViewModule } from "./notification-view/package.module";
import { DnFilterSelectModule } from "src/dn-components/dn-filter-select/src/package.module";
import { DnListBoxModule } from "src/dn-components/dn-list-box/src/package.module";
import { AskQuestionComponent } from "src/modules/help/ask-question.component";
import { DnFormTextareaModule } from "src/dn-components/dn-form-textarea/src/package.module";
import { DnDragDropUploadModule } from "src/dn-components/dn-drag-drop-upload/src/package.module";
import { ChipsModule } from "primeng/chips";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NotificationDialogComponent } from "src/dn-components/dn-notification-dialog/src/notification-dialog.component";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { DnSearchDrawerModule } from "src/dn-components/dn-search-drawer/src/package.module";
import { DnDialogPreviewComponent } from "src/dn-components/dn-dialog-preview/src/dn-dialog-preview.component";
import { SearchPipe } from "./search-panel/pipe/search.pipe";
import { DnDialogFullScreenComponent } from 'src/dn-components/dn-dialog-fullscreen/src/dn-dialog-fullscreen.component';
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { DnTableModule } from 'src/dn-components/dn-table/src/package.module';
import { ChartModule } from 'primeng/chart';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { AgWordCloudModule } from 'angular4-word-cloud';
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { HttpInterceptorJwtAuthService } from 'src/services/http-interceptor-jwt-auth.service';
import { DnSnackBarModule } from 'src/dn-components/dn-snackbar/src/package.module';
import { RouteGuardService } from 'src/services/route-guard.service';

registerLocaleData(en);
let appRoutes = [
  { path: "", redirectTo: "/login", pathMatch: "full" },
  { path: "login", loadChildren: "../modules/login/login.module#LoginModule" },
  {
    path: "dashboard",
    component: NavigationComponent,
    loadChildren: "../modules/dashboard/dashboard.module#DashboardModule",
    canActivate: [RouteGuardService]
  },
   {
    path: "currency-exchange",
    component: NavigationComponent,
    loadChildren: "../modules/currency-exchange/currency-exchange.module#CurrencyExchangeModule",
  canActivate: [RouteGuardService]
  },
  {
    path: "landing-page",
    component: NavigationComponent,
    loadChildren: "../modules/landing-page/landing-page.module#LandingPageModule",
 canActivate: [RouteGuardService]
  },
  {
    path: "manageuser",
    component: NavigationComponent,
    loadChildren: "../modules/manage-user/manage-user.module#ManageUserModule",
    canActivate: [RouteGuardService]
  },
  {
    path: "project",
    component: NavigationComponent,
    loadChildren: "../modules/project/project.module#ProjectModule",
    canActivate: [RouteGuardService]
  },
  {
    path: "rfp",
    component: NavigationComponent,
    loadChildren: "../modules/rfp/rfp.module#RFPModule",
    canActivate: [RouteGuardService]
  },
  {
    path: "baseline-pricing",
    component: NavigationComponent,
    loadChildren: "../modules/rfp/rfp.module#RFPModule",
    canActivate: [RouteGuardService]
  },
  {
    path: "get",
    component: NavigationComponent,
    loadChildren: "../modules/project/project.module#ProjectModule",
    canActivate: [RouteGuardService]
  },
  {
    path: "manage-supplier",
    component: NavigationComponent,
    loadChildren:
      "../modules/manage-organization/manage-organization.module#ManageOrganizationModule",
    canActivate: [RouteGuardService]
  },
  {
    path: "my-organization",
    component: NavigationComponent,
    loadChildren:
      "../modules/my-organization/my-organization.module#MyOrganizationModule",
    canActivate: [RouteGuardService]
  },
  {
    path: "report",
    component: NavigationComponent,
    loadChildren:
      "../modules/report/report.module#ReportModule",
    canActivate: [RouteGuardService]
  },
  // { path: "help", component: NavigationComponent, loadChildren: "../modules/support/support.module#SupportModule",canActivate: [RouteGuardService]},
  
  { path: "help", component: NavigationComponent, loadChildren: "../modules/support/support.module#SupportModule" ,canActivate: [RouteGuardService]},
  { path: "help-user", component: NavigationComponent, loadChildren: "../modules/support-user/support-user.module#SupportUserModule" ,canActivate: [RouteGuardService]},
  
  {
    path: "settings",
    component: NavigationComponent,
    loadChildren:
      "../modules/settings/setting.module#SettingModule",
    canActivate: [RouteGuardService]
  }
];

@NgModule({
  declarations: [
    AppComponent,
    SearchPanelComponent,
    UserProfileComponent,
    NavigationComponent,
    ChangePassComponent,
    ForgetPassComponent,
    ForgetPassAuthComponent,
    NewPassComponent,
    DeleteDialogComponent,
    ErrorDialogComponent,
    InfoDialogComponent,
    SuccessDialogComponent,
    AskQuestionComponent,
    NotificationDialogComponent,
    DnDialogPreviewComponent,
    DnDialogFullScreenComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NoopAnimationsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSidenavModule,
    MatIconModule,
    MatBadgeModule,
    MatTooltipModule,
    DnDrawerPanelModule,
    DnFormTextModule,
    DnFormSelectModule,
    DnProfileDrawerModule,
    DnNotificationDrawerModule,
    DnSearchDrawerModule,
    DnFilterSelectModule,
    MatTabsModule,
    DnListBoxModule,
    NotificationViewModule,
    DnFormDateModule,
    MatButtonModule,
    MatProgressBarModule,
    DnThemeSelectModule,
    DnFormTextareaModule,
    DnDragDropUploadModule,
    MatInputModule,
    MatAutocompleteModule,
    MatToolbarModule,
    ChipsModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxDocViewerModule,
    DnTableModule,
    MatRadioModule,
    ChartModule,Ng2GoogleChartsModule,
    AgWordCloudModule.forRoot(),
    RouterModule.forRoot(appRoutes, { useHash: false }),
    NgZorroAntdModule,
    NgxUiLoaderModule,
    DnSnackBarModule
  ],
  providers: [{provide:HTTP_INTERCEPTORS,useClass:HttpInterceptorJwtAuthService,multi:true},{ provide: NZ_I18N, useValue: en_US }],
  entryComponents: [
    DeleteDialogComponent,
    NotificationDialogComponent,
    ErrorDialogComponent,
    InfoDialogComponent,
    SuccessDialogComponent,
    AskQuestionComponent,
    DnDialogPreviewComponent,
    DnDialogFullScreenComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
