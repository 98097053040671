import { Component, Input, Output, EventEmitter } from "@angular/core";
import { drawerSearchConfig } from './dn-search-drawer.model';

@Component({
    selector: 'dn-search-drawer',
    templateUrl: './dn-search-drawer.component.html',
    styleUrls: ['./dn-search-drawer.component.scss']
})

export class DnSearchDrawerComponent {

    @Input() drawerSearchConfig: drawerSearchConfig;
    @Output() closedDrawer: EventEmitter<any> = new EventEmitter();
    @Output() displayData: EventEmitter<any> = new EventEmitter();

    constructor() {

    }

    ngOnInit() { }

    closeDrawer() {
        this.drawerSearchConfig.isActive = false;
        this.closedDrawer.emit();
    }
}
