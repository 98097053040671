import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from 'protractor';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { EndPointConfig } from "src/app/_config/endpoint.config";
import { AclInfo } from "src/app/navigation/acl";
import { environment } from "src/environments/environment";

export const TOKEN = 'token'
export const AUTHENTICATED_USER = 'authenticatedUser'
export const AUTHENTICATED_USER_AUTHORITIES = "authenticatedUserAuthorities";
export const SESSION_USER = "sessionUser";
export const SESSION_USER_INITIALS = "sessionUserInitials";
export const SESSION_ORG = "sessionOrg";
export const SESSION_ORG_NAME = "sessionOrgName";
export const SESSION_ORG_TYPE = "sessionOrgType";
export const REMEMBER_ME = "saveState";
export const ROLE_TYPE = "roleType";

//Dikshit
@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  authUrl: string;
  aclModuleMap = new Map();

  constructor(private httpClient: HttpClient) {
    this.authUrl = new EndPointConfig().baseUrl;
  }

  authenticate(username, password, isRememberMe): Observable<HttpResponse<Config>> {

    return this.httpClient.post<Config>(
      `${this.authUrl}/login`, { username, password }, { observe: 'response' }).pipe(
        map(
          (data: any) => {

            if (data['body']['user']['ssoRequired'] == false) {

              localStorage.setItem(AUTHENTICATED_USER_AUTHORITIES, data['body']['authorities']);
              localStorage.setItem(AUTHENTICATED_USER, JSON.stringify(data['body']['user']));
              localStorage.setItem(SESSION_USER, data['body']['user']['id']);
              localStorage.setItem(SESSION_USER_INITIALS, data['body']['user']['firstName'][0] + data['body']['user']['lastName'][0]);
              localStorage.setItem(SESSION_ORG, data['body']['user']['organization']);
              localStorage.setItem(SESSION_ORG_NAME, data['body']['user']['organizationText']);
              localStorage.setItem(SESSION_ORG_TYPE, data['body']['user']['organizationType']);
              localStorage.setItem(ROLE_TYPE, data['body']['user']['roleText'])

              if (isRememberMe) {
                localStorage.setItem(REMEMBER_ME, '1');
                localStorage.setItem(TOKEN, data['headers'].get('authorization'));
              }
              else {
                localStorage.setItem(REMEMBER_ME, '0');
                sessionStorage.setItem(TOKEN, data['headers'].get('authorization'));
              }
              return data;
            } else {
              throw new Error('sso login required.');
            }
          }
        )

      );
  }

  authenticateSsoCode(code): Observable<HttpResponse<Config>> {
    return this.httpClient.post<Config>(environment.baseUrl + '/sso/token', code, { observe: 'response' }).pipe(
      map(
        data => {
          if (data['body']['authorized'] == true) {

            localStorage.setItem(AUTHENTICATED_USER_AUTHORITIES, data['body']['authorities']);
            localStorage.setItem(AUTHENTICATED_USER, JSON.stringify(data['body']['user']));
            localStorage.setItem(SESSION_USER, data['body']['user']['id']);
            localStorage.setItem(SESSION_USER_INITIALS, data['body']['user']['firstName'][0] + data['body']['user']['lastName'][0]);
            localStorage.setItem(SESSION_ORG, data['body']['user']['organization']);
            localStorage.setItem(SESSION_ORG_NAME, data['body']['user']['organizationText']);
            localStorage.setItem(SESSION_ORG_TYPE, data['body']['user']['organizationType']);
            localStorage.setItem(ROLE_TYPE, data['body']['user']['roleText'])

            localStorage.setItem(REMEMBER_ME, '0');
            sessionStorage.setItem(TOKEN, data['body']['Authorization']);

            return data;
          } else {
            throw new Error('User is not authorized');
          }
        }
      )
    );
  }
  getAclModuleMap() {
    let moduleMap = new Map();
    var aclModule: AclInfo[] = JSON.parse(localStorage.getItem("aclModule"));
    aclModule.forEach((items) => {
      //   moduleMap.set(items.moduleName, items.accessLevel);
    });

    return moduleMap;
  }
  getAuthenticatedToken() {
    if (localStorage.getItem(REMEMBER_ME) == '1')
      if (this.getAuthenticatedUser()) return localStorage.getItem(TOKEN);
    if (localStorage.getItem(REMEMBER_ME) == '0')
      if (this.getAuthenticatedUser()) return sessionStorage.getItem(TOKEN);
  }

  getAuthenticatedUser() {
    return localStorage.getItem(AUTHENTICATED_USER);
  }

  updateSession(data: any[]) {

    localStorage.setItem("organizationId", data["user"].organizationId);
    localStorage.setItem(
      "userName",
      data["user"].userFirstName + " " + data["user"].userLastName
    );
    localStorage.setItem("userId", data["user"].userId);
    localStorage.setItem(
      "clientId",
      data["user"].userFirstName.charAt(0) + data["user"].userLastName.charAt(0)
    );
  }

  isUserLoggedIn() {
    if (localStorage.getItem(REMEMBER_ME) == '1' && localStorage.getItem(TOKEN) != null && localStorage.getItem(TOKEN) != undefined)
      return true;
    else if (localStorage.getItem(REMEMBER_ME) == '0' && sessionStorage.getItem(TOKEN) != null && sessionStorage.getItem(TOKEN) != undefined)
      return true;
    else
      false;
  }

  // logout(userId): Observable<any> {

  //   this.httpClient.post<any>(`${AUTH_URL}/logout/${userId}`,'');
  //   // localStorage.removeItem('userId')
  // }

  userLogout(userId): Observable<any> {
    localStorage.clear();
    sessionStorage.clear();
    return this.httpClient.post<any>(`${this.authUrl}/logout/${userId}`, null);
  }
}
