import { Directive, Input, ElementRef, Renderer2 } from "@angular/core";
import { GlobalService } from 'src/services/global.service';

@Directive({
    selector: '[dn-validation]',
})

export class DnValidationDirective {
    @Input() config;
    @Input() dataModel;

    constructor(
        private elemRef: ElementRef,
        private renderer: Renderer2,
        private globalService: GlobalService
    ) { }

    validateRules() {
        if (this.config.attributes && this.config.attributes.disable) {
            return;
        }
        // if (this.config.attributes && this.config.attributes.isMandatory && !this.dataModel[this.config.fieldKey]) {
        if (this.config.attributes && this.config.attributes.isMandatory 
                    && (this.dataModel[this.config.fieldKey] ==null ||
                             (this.dataModel[this.config.fieldKey].length != undefined && (this.dataModel[this.config.fieldKey].length==0 || this.dataModel[this.config.fieldKey].trim().length==0)))) {
            // this.config.attributes.errorMessage = "This field is required.";
            if (this.config.attributes.errorMessage == null || this.config.attributes.errorMessage == '') {
                if (this.config.attributes.label == undefined) {
                    this.config.attributes.errorMessage = "Required Field";
                } else {
                    this.config.attributes.errorMessage = "Enter " + this.config.attributes.label;
                }
                return;
            } else {
                return;
            }

        }
        
        if (this.config.attributes && this.config.attributes.pattern == 'emailOnly' && this.dataModel[this.config.fieldKey] && this.dataModel[this.config.fieldKey].length > 2) {
            this.config.attributes.errorMessage = '';
            let regex: RegExp = new RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,4}$");

            if (regex.test(this.dataModel[this.config.fieldKey])) {

                this.config.attributes.errorMessage = '';
                return true;
            } else {
                this.config.attributes.errorMessage = 'Please enter valid email address'

                return false;
            }

        }

        // debugger;

        if (this.config.attributes && this.config.attributes.pattern == 'passwordOnly' && this.dataModel[this.config.fieldKey]) {
            
            this.config.attributes.errorMessage = '';
            let regex = new RegExp(
                '^(?=.*[!@#$%^&?*"\'\\[\\]\\{\\}<>/\\(\\)=\\\\\\-_´+`~\\:;,\\.€\\|])',
            );
            if (regex.test(this.dataModel[this.config.fieldKey])) {
                this.config.attributes.errorMessage = '';
            } else {
                this.config.attributes.errorMessage = 'Password must contain a special character'
                return false;
            }

        }

        this.config.attributes.errorMessage = "";

        if (this.config.attributes && this.config.attributes.isMandatory && this.config.attributes.rules && this.config.attributes.rules.length > 0) {
            let rules = this.config.attributes.rules;

            for (var i = 0; i < this.config.attributes.rules.length; i++) {
                if (typeof rules[i] == 'object' && eval(rules[i].rule)) {
                    this.config.attributes.errorMessage = rules[i].message;
                    break;
                }
            }
        }
    }

    validateDom() {
        let errorElem = this.elemRef.nativeElement.querySelector('.error_message_cnt');
        let matFormElem = this.elemRef.nativeElement.querySelector('mat-form-field');
        if (this.config.attributes && !this.config.attributes.disable) {
            if (this.config.attributes.errorMessage) {
                this.renderer.addClass(matFormElem, "mat-form-field-invalid");
                errorElem.innerHTML = this.config.attributes.errorMessage;
                this.globalService.addErrorObject(this.config);
            }
            else {
                this.renderer.removeClass(matFormElem, "mat-form-field-invalid");
                errorElem.innerHTML = "";
                this.globalService.clearErrorObject(this.config);
                // Step 2
                this.globalService.removeValidObject(this.config);
            }
        }

    }

    refreshValidation() {
        this.validateRules();
        this.validateDom();
    }

    ngOnInit() {
        this.config.api['refreshValidation'] = () => { this.refreshValidation(); }
    }

    ngAfterViewInit() {
        if (this.config.attributes && this.config.attributes.validateOninit) {
            this.refreshValidation();
        }
    }
}